/*
 *
 * Chat input reducer
 *
 */

import { SELECT_FILE, REMOVE_FILE, SEND_MESSAGE, UPDATE_PROGRESS } from './constants';

const initialState = {
  attachment: null,
  progress: -1,
};

export default function chatInputReducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_FILE:
      return { ...state, attachment: action.payload.file, progress: -1 };
    case REMOVE_FILE:
      return { ...state, attachment: null, progress: -1 };
    case SEND_MESSAGE:
      return { ...state, attachment: null };
    case UPDATE_PROGRESS:
      return { ...state, progress: action.payload.progress };
    default:
      return state;
  }
}
