import { INCOMPLETE_SURVEYS } from '../constants';
import queryString from 'query-string';
import localStorage from '../../../utils/localStorage';

export const getIncompleteSurveys = () => localStorage.getItem(INCOMPLETE_SURVEYS) ?? [];

export const getIncompleteSurvey = (nodeId, sdi, edi, ldi, wdi, pndi) => {
  const incompleteSurveys = getIncompleteSurveys();
  return incompleteSurveys.find(
    (survey) =>
      survey.nodeId === nodeId &&
      survey.sdi === sdi &&
      survey.edi === edi &&
      survey.ldi === ldi &&
      survey.wdi === wdi &&
      survey.pndi === pndi,
  );
};

export const addIncompleteSurvey = (feedbackId, nodeId, flowId) => {
  const { sdi, edi, ldi, wdi, pndi } = queryString.parse(window.location.search);

  const isFeedbackExists = !!getIncompleteSurvey(nodeId, sdi, edi, ldi, wdi, pndi);

  if (!isFeedbackExists) {
    const incompleteSurveys = getIncompleteSurveys();

    incompleteSurveys.push({
      feedbackId,
      nodeId,
      flowId,
      sdi,
      edi,
      ldi,
      wdi,
      pndi,
    });
    localStorage.setItem(INCOMPLETE_SURVEYS, incompleteSurveys);
  }
};

export const removeIncompleteSurvey = (feedbackId) => {
  const incompleteSurveys = getIncompleteSurveys();
  const updatedIncompleteSurveys = incompleteSurveys.filter((survey) => survey.feedbackId !== feedbackId);

  localStorage.setItem(INCOMPLETE_SURVEYS, updatedIncompleteSurveys);
};
