export class SmartRequestHandler {
  constructor() {
    this.currentRequest = null;
    this.nextRequest = null;
  }

  async handleRequest(requestFunction) {
    if (this.currentRequest) {
      // If there's a request in progress, replace any pending next request
      this.nextRequest = requestFunction;

      return new Promise((resolve, reject) => {
        this.nextRequest = { requestFunction, resolve, reject };
      });
    }

    // If no request is in progress, execute immediately
    return this.executeRequest(requestFunction);
  }

  async executeRequest(requestFunction) {
    this.currentRequest = requestFunction();

    try {
      const result = await this.currentRequest;
      this.currentRequest = null;

      // Check if there's a next request queued
      if (this.nextRequest) {
        const { requestFunction, resolve, reject } = this.nextRequest;
        this.nextRequest = null;
        // Execute the next request
        this.executeRequest(requestFunction).then(resolve).catch(reject);
      }

      return result;
    } catch (error) {
      this.currentRequest = null;
      throw error;
    }
  }
}
