/**
 *
 * ChatInput
 *
 */

import FileUploadProgress from '@pisano/feedback-core/app/components/FileUploadProgress';
import Translate from '@pisano/feedback-core/app/containers/Translate/Loadable';
import formatBytes from '@pisano/feedback-core/app/utils/formatBytes';
import injectReducer from '@pisano/feedback-core/app/utils/injectReducer';
import injectSaga from '@pisano/feedback-core/app/utils/injectSaga';
import isOperatingHour from '@pisano/feedback-core/app/utils/operating';
import className from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { removeFile, selectFile, sendMessage } from './actions';
import AttachmentButton from './images/attachment.svg';
import SendButton from './images/send.svg';
import reducer from './reducer';
import saga from './saga';

export class ChatInput extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isSendButtonDisabled: true,
    };
  }

  componentDidMount() {
    this.waitForInputs = setInterval(() => {
      this.chatInput = (this.context.document || document).getElementById('chat-input');
      this.fileInput = (this.context.document || document).getElementById('file-input');
      if (!this.chatInput) return;
      clearInterval(this.waitForInputs);
      // Bind the keydown event on textarea to the autosize function.
      const autosize = () => {
        setTimeout(() => {
          this.chatInput.style.height = '0';
          // +2 = top + bottom border width
          this.chatInput.style.height = `${this.chatInput.scrollHeight + 2}px`;
        }, 0);
      };
      ['keydown', 'cut', 'paste', 'drop'].forEach((event) => {
        this.chatInput.addEventListener(event, autosize);
      });
      this.chatInput.nextSibling.addEventListener('click', autosize);
      this.chatInput.addEventListener('keydown', (event) => {
        if (event.keyCode === 13 && !event.shiftKey) {
          event.preventDefault();
          this.sendMessage();
        }
      });
      this.chatInput.addEventListener('input', (event) => {
        this.updateSendButtonStatus({ message: event.target.value });
      });
    }, 100);

    const {
      node: { operating24 },
    } = this.props;

    if (!this.operatingInterval && !operating24) {
      this.operatingInterval = setInterval(() => {
        const { node } = this.props;
        if (node.operating24) {
          clearInterval(this.operatingInterval);
        } else {
          this.setState({ isOperating: isOperatingHour(node.operatingHours) });
        }
      }, 60000);
    }
  }

  componentWillUnmount() {
    // If the component somehow unmounts before the chat input loads,
    // clear the interval so that no memory leak will occur.
    if (this.waitForInputs) {
      clearInterval(this.waitForInputs);
    }

    if (this.operatingInterval) {
      clearInterval(this.operatingInterval);
    }
  }

  updateSendButtonStatus({ message = this.chatInput ? this.chatInput.value : '', attachment = this.props.attachment }) {
    this.setState({ isSendButtonDisabled: !(attachment || message.length) });
  }

  sendMessage() {
    const { sendMessage, feedbackId, attachment } = this.props;
    if (this.state.isSendButtonDisabled) return;

    this.updateSendButtonStatus({ message: '', attachment: null });

    sendMessage({ message: this.chatInput.value, feedbackId, attachment });
    this.chatInput.value = '';
  }

  handleFileSelect(event) {
    const { handleFileSelect } = this.props;
    handleFileSelect({ file: event.target.files[0] });
    this.updateSendButtonStatus({ attachment: true });
    this.fileInput.value = null;
  }

  handleAttachmentRemove() {
    this.props.handleAttachmentRemove();
    this.updateSendButtonStatus({ attachment: false });
  }

  renderOperatingHoursWarning() {
    return (
      <div className="chat-operating-hours-container">
        <Translate message="outOfOperatingHours" />
      </div>
    );
  }

  renderQueueWarning() {
    return (
      <div>
        <div className="chat-input-attachment-progress" style={{ display: 'none' }} />
        <div className="chat-input-container">
          <Translate component="textarea" className="chat-input is-disabled" />
          <label className="attachment-button-container">
            <AttachmentButton className="attachment-button is-disabled" />
          </label>

          <SendButton role="button" className="send-button is-disabled" />
        </div>
      </div>
    );
  }

  renderArchivedFeedbackMessage() {
    return (
      <div className="chat-input-container">
        <Translate
          component="textarea"
          className="chat-input is-disabled"
          attributes={{
            placeholder: 'closedMessage',
          }}
        />
      </div>
    );
  }

  render() {
    const { attachment, progress, node, status, preventUpload } = this.props;
    const { isOperating } = this.state;

    const sendButtonClass = className('send-button', {
      'is-disabled': this.state.isSendButtonDisabled,
    });

    if ((isOperating === undefined && !node.isOperating) || (isOperating !== undefined && !isOperating)) {
      return this.renderOperatingHoursWarning();
    }

    if (status === 'queued') {
      return this.renderQueueWarning();
    }
    if (status === 'closed' || status === 'archived') {
      return this.renderArchivedFeedbackMessage();
    }

    return (
      <div>
        <div
          className="chat-input-attachment-progress"
          style={{ display: attachment || progress >= 0 ? 'block' : 'none' }}
        >
          <FileUploadProgress
            isLoading={progress >= 0}
            progress={progress}
            cancel={() => this.handleAttachmentRemove()}
            file={{
              name: attachment ? attachment.name : '',
              type: attachment ? attachment.type : '',
              size: attachment ? formatBytes(attachment.size) : '',
            }}
          />
        </div>
        <div className="chat-input-container">
          <Translate
            component="textarea"
            id="chat-input"
            className="chat-input"
            attributes={{
              placeholder: 'yourMessage',
            }}
          />

          {!preventUpload && (
            <label className="attachment-button-container">
              <AttachmentButton className="attachment-button" />
              <input
                id="file-input"
                style={{ display: 'none' }}
                type="file"
                accept="image/*"
                onChange={(event) => this.handleFileSelect(event)}
              />
            </label>
          )}

          <SendButton role="button" tabIndex={0} className={sendButtonClass} onClick={() => this.sendMessage()} />
        </div>
      </div>
    );
  }
}

ChatInput.propTypes = {
  feedbackId: PropTypes.string.isRequired,
  node: PropTypes.object.isRequired,
  sendMessage: PropTypes.func.isRequired,
  handleAttachmentRemove: PropTypes.func,
  handleFileSelect: PropTypes.func,
  attachment: PropTypes.object,
  progress: PropTypes.number,
  status: PropTypes.string,
  preventUpload: PropTypes.bool,
};

ChatInput.defaultProps = {
  attachment: null,
  progress: -1,
};

ChatInput.contextTypes = {
  document: PropTypes.any,
};

const mapStateToProps = (state) => ({
  attachment: state.chatInput.attachment,
  progress: state.chatInput.progress,
});

export function mapDispatchToProps(dispatch) {
  return {
    sendMessage: (payload) => dispatch(sendMessage(payload)),
    handleFileSelect: (payload) => dispatch(selectFile(payload)),
    handleAttachmentRemove: (payload) => dispatch(removeFile(payload)),
  };
}

const withReducer = injectReducer({ key: 'chatInput', reducer });
const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: 'chatInput', saga });

export default compose(withReducer, withSaga, withConnect)(ChatInput);
