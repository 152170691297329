import PropTypes from 'prop-types';

function updateDocumentTitle(title) {
  if (title) {
    document.title = title;
  }
}

function injectStyles(styles, name) {
  if (styles) {
    let styleElement = document.querySelector(`[data-custom-${name}-style-injector="true"]`);

    if (!styleElement) {
      styleElement = document.createElement('style');
      styleElement.type = 'text/css';
      styleElement.setAttribute(`data-custom-${name}-style-injector`, 'true');
      document.head.appendChild(styleElement);
    }

    styleElement.innerHTML = styles;
  }
}

function injectCssUrl(cssUrl, name) {
  if (cssUrl) {
    let linkElement = document.querySelector(`[data-custom-${name}-css-injector="true"]`);

    if (!linkElement) {
      linkElement = document.createElement('link');
      linkElement.rel = 'stylesheet';
      linkElement.setAttribute(`data-custom-${name}-css-injector`, 'true');
      document.head.appendChild(linkElement);
    }

    linkElement.href = cssUrl;
  }
}

export function customContentInjector({ title, styles, cssUrl, name }) {
  updateDocumentTitle(title);
  injectStyles(styles, name);
  injectCssUrl(cssUrl, name);
}

customContentInjector.propTypes = {
  title: PropTypes.string,
  styles: PropTypes.string,
  cssUrl: PropTypes.string,
  name: PropTypes.string.isRequired,
};
