export class PisanoError extends Error {
  date;

  messageKey;

  params;

  /**
   * @param  {string} messageKey Translation message key
   * @param  {Array}  params     Params for the general Error object
   * @return {UnreportedError}
   */
  constructor(messageKey, ...params) {
    super(...params);
    this.name = 'PisanoError';
    this.messageKey = messageKey || 'systemError';
    this.date = new Date();
    this.params = params;

    // Maintains proper stack trace for where our error was thrown
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    }
  }
}

export class ReportedError extends PisanoError {
  constructor(...params) {
    super(...params);
    this.name = 'ReportedError';
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    }
  }
}

export class UnreportedError extends PisanoError {
  constructor(...params) {
    super(...params);
    this.name = 'UnreportedError';
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    }
  }
}

export class XhrError extends UnreportedError {
  response;

  /**
   * @param  {Response} response XHR response object
   * @param  {Array}    params   Params for the general Error object
   * @return {XhrError}
   */
  constructor(response, ...params) {
    super(...params);
    this.name = 'XhrError';
    this.response = response;

    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    }
  }
}

export const createError = (Type, ...params) => {
  const err = new Type(...params);
  err.message = JSON.stringify(err);
  if (!err.stack) {
    err.stack = new Error(err.message).stack;
  }
  return err;
};

/**
 * Returns whether the given error should be reported.
 *
 * @param  {Error} error
 * @return {boolean}
 */
export const shouldReport = (error) =>
  error instanceof ReportedError || Object.getPrototypeOf(error) === Error.prototype;
