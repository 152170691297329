import {
  regexCreditCards,
  regexEmail,
  regexPassportNumbers,
  regexPhoneNumbers,
  regexTCKN,
} from '../../../utils/regexConstants';

function matchWithRegex(text) {
  let personalDataToBeMasked = [];
  personalDataToBeMasked = personalDataToBeMasked.concat(
    regexCreditCards,
    regexEmail,
    regexTCKN,
    regexPassportNumbers,
    regexPhoneNumbers,
  );
  personalDataToBeMasked.forEach((reg) => {
    const pattern = new RegExp(reg.pattern, 'gi');
    const match = text.match(pattern);
    if (match) {
      match.forEach((matchedText) => {
        text = text.replace(matchedText, reg.maskPattern);
      });
    }
  });
  return text;
}

export function maskPersonalData(feedback) {
  feedback.responses.forEach((res) => {
    if (res.hidePersonalData === true) {
      res.body = matchWithRegex(res.body);
    }
  });
}
