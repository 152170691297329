import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import React from 'react';
import Translate from '../../containers/Translate/Loadable';
import { customContentInjector } from '../../utils/customContentInjector';
import { shouldReport } from '../../utils/error';
import { SentryReport } from '../../utils/sentry';

const ErrorBoundaryContent = ({ children, wrapper: ErrorWrapper, cssFilePath, displayError, errorMessage }) => {
  const Wrapper = ErrorWrapper || React.Fragment;
  const Message = <Translate message={errorMessage} />;

  if (!displayError && errorMessage) {
    return null;
  }

  if (errorMessage) {
    customContentInjector({ cssUrl: cssFilePath, name: 'error' });

    return (
      <Wrapper>
        <div>{Message}</div>
      </Wrapper>
    );
  }

  return children;
};

ErrorBoundaryContent.propTypes = {
  children: PropTypes.node,
  displayError: PropTypes.bool,
  errorMessage: PropTypes.string,
  cssFilePath: PropTypes.string,
  wrapper: PropTypes.any,
};

class ErrorBoundary extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = { errorMessage: null };
  }

  componentDidCatch(error, info) {
    if (shouldReport(error)) {
      SentryReport({
        error,
        info,
        state: this.context.store.getState(),
      });
    }
    this.setState({ errorMessage: error.messageKey || 'systemError' });
  }

  render() {
    const { children, wrapper: ErrorWrapper, loadCss, displayError } = this.props;

    return (
      <Sentry.ErrorBoundary>
        <ErrorBoundaryContent
          wrapper={ErrorWrapper}
          cssFilePath={loadCss}
          displayError={displayError}
          errorMessage={this.state.errorMessage}
        >
          {children}
        </ErrorBoundaryContent>
      </Sentry.ErrorBoundary>
    );
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  displayError: PropTypes.bool,
  loadCss: PropTypes.string,
  wrapper: PropTypes.any,
};

ErrorBoundary.defaultProps = {
  displayError: true,
};

ErrorBoundary.contextTypes = {
  store: PropTypes.object.isRequired,
};

export default ErrorBoundary;
