import { configureSaga } from '@pisano/feedback-core/app/containers/Flow/sagas/nodeSaga';
import { requestText } from '@pisano/feedback-core/app/utils/request';
import { call, select } from 'redux-saga/effects';
import defaultWebCssUrl from '../styles/feedback-web.scss';
import defaultWidgetCssUrl from '../styles/feedback-widget.scss';

function* postLoadNodeSagaHook(simplifiedNode) {
  const state = yield select();
  if (!simplifiedNode.cssUrl) {
    simplifiedNode.cssUrl = state.widget ? defaultWidgetCssUrl : defaultWebCssUrl;
    simplifiedNode.css = yield call(requestText, simplifiedNode.cssUrl);
  }
}

configureSaga.configureHooks({
  postLoadHook: postLoadNodeSagaHook,
});
