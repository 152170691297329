/*
 *
 * Widget constants
 *
 */

// Action constants
export const LOAD_WIDGET = 'app/Widget/LOAD_WIDGET';
export const LOAD_WIDGET_SUCCESS = 'app/Widget/LOAD_WIDGET_SUCCESS';
export const LOAD_WIDGET_CSS = 'app/Widget/LOAD_WIDGET_CSS';
export const LOAD_WIDGET_CSS_SUCCESS = 'app/Widget/LOAD_WIDGET_CSS_SUCCESS';
export const LOAD_WIDGET_ERROR = 'app/Widget/LOAD_WIDGET_ERROR';
export const LOAD_CUSTOMER = 'app/Widget/LOAD_CUSTOMER';
export const LOAD_CUSTOMER_SUCCESS = 'app/Widget/LOAD_CUSTOMER_SUCCESS';
export const LOAD_CUSTOMER_ERROR = 'app/Widget/LOAD_CUSTOMER_ERROR';
export const OPEN_WIDGET = 'app/Widget/OPEN_WIDGET';
export const OPEN_WIDGET_ONCE = 'app/Widget/OPEN_WIDGET_ONCE';
export const CLOSE_WIDGET = 'app/Widget/CLOSE_WIDGET';
export const SHOW_WIDGET = 'app/Widget/SHOW_WIDGET';
export const HIDE_WIDGET = 'app/Widget/HIDE_WIDGET';
export const OPEN_CHAT = 'app/Widget/OPEN_CHAT';
export const ADD_PREVIOUS_FEEDBACK = 'app/Widget/ADD_PREVIOUS_FEEDBACK';
export const OPEN_PREVIOUS_FEEDBACK = 'app/Widget/OPEN_PREVIOUS_FEEDBACK';
export const SHOW_PREVIOUS_FEEDBACKS = 'app/Widget/SHOW_aPREVIOUS_FEEDBACKS';
export const HIDE_PREVIOUS_FEEDBACKS = 'app/Widget/HIDE_PREVIOUS_FEEDBACKS';
export const SEND_POST_MESSAGE_FROM_WIDGET = 'app/Widget/SEND_POST_MESSAGE_FROM_WIDGET';
export const SET_WIDGET_INITIAL_DISPLAY_STATUS = 'app/Widget/SET_WIDGET_INITIAL_DISPLAY_STATUS';

// Others
export const WIDGET_PREFIX = 'PSN-';
export const MOBILE_REGEX = /\bmobile\b/i;
export const PREVIOUS_FEEDBACKS = 'PREVIOUS_FEEDBACKS';
