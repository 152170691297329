/*
 *
 * Chat actions
 *
 */

import {
  GET_CHAT_DETAIL,
  CHAT_DETAIL_LOADED,
  CHAT_DETAIL_LOADING_ERROR,
  SET_TIMELINE_ITEMS,
  ADD_TIMELINE_ITEM,
  SET_TIMELINE_ITEM,
  CHAT_RATED,
  CHAT_RATING_ERROR,
  UPDATE_RATE_CHAT_OPTIONS,
  SET_LOADING,
  ARCHIVE_MODAL,
  SEND_ARCHIVE_FEEDBACK,
} from './constants';

export function getChatDetail({ feedbackId, token }) {
  return {
    type: GET_CHAT_DETAIL,
    payload: { feedbackId, token },
  };
}

export function chatDetailLoaded(chatDetail) {
  return {
    type: CHAT_DETAIL_LOADED,
    payload: chatDetail,
  };
}

export function chatDetailLoadingError(errorResponse) {
  return {
    type: CHAT_DETAIL_LOADING_ERROR,
    payload: errorResponse,
  };
}

export function setTimelineItems(timelineItems) {
  return {
    type: SET_TIMELINE_ITEMS,
    payload: timelineItems,
  };
}

export function addTimelineItem(item) {
  return {
    type: ADD_TIMELINE_ITEM,
    payload: item,
  };
}

export function setTimelineItem(id, item) {
  return {
    type: SET_TIMELINE_ITEM,
    payload: { id, item },
  };
}

export function chatRated(payload) {
  return {
    type: CHAT_RATED,
    payload,
  };
}

export function chatRatingError(payload) {
  return {
    type: CHAT_RATING_ERROR,
    payload,
  };
}

export function updateRateChatOptions(chatDetail) {
  return {
    type: UPDATE_RATE_CHAT_OPTIONS,
    payload: chatDetail,
  };
}

export function setLoading(isLoading) {
  return {
    type: SET_LOADING,
    payload: isLoading,
  };
}

export function archiveModal() {
  return {
    type: ARCHIVE_MODAL,
  };
}

export function sendArchiveFeedback() {
  return {
    type: SEND_ARCHIVE_FEEDBACK,
  };
}
