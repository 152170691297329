/**
 *
 * ChatRating
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { compose } from 'redux';
import noop from 'lodash/noop';
import Translate from '@pisano/feedback-core/app/containers/Translate/Loadable';
import { rateChat } from './actions';
import { AVAILABLE_CHAT_RATINGS } from './constants';

export function ChatRating(props) {
  const { id, rateChat, node, chatRatingMessage, isRated, selectedRating } = props;

  const rateChatIfNotRated = isRated ? noop : rateChat;
  const ratingMessage = chatRatingMessage || 'defaultChatRatingMessage';

  const getRateChatButtonClass = (optionWeight) =>
    classNames(
      'comment-action-body',
      'rate-button',
      { disabled: isRated },
      { selected: selectedRating === optionWeight },
    );

  return (
    <div className="sender-action-group left">
      <div className="fake-user">
        <div className="sender-label">
          <div className="sender-info">{node.name}</div>
          <div className="sender-avatar" style={{ background: `url(${node.logo_url})` }} />
        </div>
      </div>
      <div className="comment-action">
        <div className="comment-action-body">
          <div className="action-body">
            <Translate message={ratingMessage} />
          </div>
        </div>
      </div>
      <div className="comment-action">
        <div className="feedback-rate">
          {AVAILABLE_CHAT_RATINGS.map((option) => (
            <div
              id={`rate-${option.weight}`}
              role="button"
              className={getRateChatButtonClass(option.weight)}
              tabIndex={0}
              key={option.weight}
              onClick={() => rateChatIfNotRated({ feedbackId: id, rate: option.weight })}
            >
              <Translate message={option.body} parseMarkdown emojify />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

ChatRating.propTypes = {
  id: PropTypes.string.isRequired,
  node: PropTypes.object.isRequired,
  chatRatingMessage: PropTypes.object,
  isRated: PropTypes.bool.isRequired,
  selectedRating: PropTypes.number,
  rateChat: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isRated: state.chat.isRated,
  selectedRating: state.chat.selectedRating,
  chatRatingMessage: state.chat.chat_rating_setting.body,
});

export const mapDispatchToProps = (dispatch) => ({
  rateChat: (payload) => dispatch(rateChat(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ChatRating);
