/**
 * Create the store with dynamic reducers
 */

import { routerMiddleware } from 'react-router-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { reduxTimeout } from 'redux-timeout';
import { PRODUCTION } from './config';
import createReducer from './reducers';
import createActionLoggerMiddleware from './redux-middlewares/actionLogger';
import analyticsMiddleware from './redux-middlewares/analytics';
import { generateDeviceID } from './utils/device';
import { generatePisanoSession } from './utils/pisanoSession';

const sagaMiddleware = createSagaMiddleware();

export default function configureStore(initialState = {}, history) {
  generateDeviceID();
  generatePisanoSession();

  window.PisanoActions = [];
  const middlewares = [
    createActionLoggerMiddleware(window.PisanoActions), // HACK: Don't use window to hold previous actions.
    analyticsMiddleware,
    sagaMiddleware,
  ];
  if (history) {
    middlewares.push(routerMiddleware(history));
  }
  middlewares.push(reduxTimeout());

  const enhancers = [applyMiddleware(...middlewares)];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  const composeEnhancers =
    !PRODUCTION && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ shouldHotReload: false })
      : compose;

  const store = createStore(createReducer(), initialState, composeEnhancers(...enhancers));

  // Extensions
  store.runSaga = sagaMiddleware.run;
  store.injectedReducers = {}; // Reducer registry
  store.injectedSagas = {}; // Saga registry

  // Make reducers hot reloadable, see http://mxs.is/googmo
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(createReducer(store.injectedReducers));
    });
  }

  return store;
}
