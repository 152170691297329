import { DEVICE_ID } from '../containers/Flow/constants';
import localStorage from './localStorage';

function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
  );
}

export const generateDeviceID = () => {
  if (localStorage.getItem(DEVICE_ID)) {
    return;
  }

  localStorage.setItem(DEVICE_ID, uuidv4());
};
