/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import { LOCATION_CHANGE } from 'react-router-redux';
import mapValues from 'lodash/mapValues';

/*
 * Global route reducer
 *
 * The reducer merges route location changes into our immutable state.
 * The change is necessitated by moving to react-router-redux@4
 *
 */

const routeInitialState = {
  location: null,
};

function routeReducer(state = routeInitialState, action) {
  switch (action.type) {
    /* istanbul ignore next */
    case LOCATION_CHANGE:
      return { ...state, location: action.payload };
    default:
      return state;
  }
}

/**
 * Global language reducer
 */
export const languageInitialState = {
  currentLanguage: {
    code: 'en',
  },
  isSelectorVisible: false,
};

export const SET_LANGUAGE = 'SET_LANGUAGE';
export const TOGGLE_LANGUAGE_SELECTOR = 'TOGGLE_LANGUAGE_SELECTOR';

export function setLanguage(language) {
  return {
    type: SET_LANGUAGE,
    payload: {
      language,
    },
  };
}

export function toggleLanguageSelector() {
  return {
    type: TOGGLE_LANGUAGE_SELECTOR,
  };
}

export function languageReducer(state = languageInitialState, action) {
  switch (action.type) {
    case SET_LANGUAGE:
      return { ...state, currentLanguage: action.payload.language };
    case TOGGLE_LANGUAGE_SELECTOR:
      return { ...state, isSelectorVisible: !state.isSelectorVisible };
    default:
      return state;
  }
}

export const SET_CUSTOM_ATTRIBUTES = 'SET_CUSTOM_ATTRIBUTES';
export function setCustomAttributes(customAttributes) {
  return {
    type: SET_CUSTOM_ATTRIBUTES,
    payload: customAttributes,
  };
}

export function customAttributesReducer(state = {}, action) {
  switch (action.type) {
    case SET_CUSTOM_ATTRIBUTES:
      return { ...state, hash: action.payload };
    default:
      return state;
  }
}

/**
 * Creates the main reducer with the dynamically injected ones
 */
export default function createReducer(injectedReducers) {
  const combineNestedReducers = (reducer) => {
    if (typeof reducer === 'object') {
      return combineReducers(mapValues(reducer, combineNestedReducers));
    }
    return reducer;
  };
  return combineReducers({
    route: routeReducer,
    language: languageReducer,
    customAttributes: customAttributesReducer,
    ...mapValues(injectedReducers, combineNestedReducers),
  });
}
