export function triggerPostMessage({ type, data, delay = 0, origin = '*' }) {
  function send() {
    window.parent.postMessage(
      JSON.stringify({
        type,
        ...data,
      }),
      origin,
    );
  }

  if (delay > 0) {
    setTimeout(() => {
      send();
    }, delay);
  } else {
    send();
  }
}
