import io from 'socket.io-client';
import config from './config';

let socket;

export default () => {
  if (!socket) {
    socket = io.connect(config.SOCKET_URL, {
      transports: ['websocket', 'polling'],
    });
  }

  return socket;
};
