const getCurrentTime = () => {
  const date = new Date();
  const hour = date.getHours();
  let minute = date.getMinutes();
  minute = minute >= 10 ? minute : '0'.concat(minute);
  return parseInt(''.concat(hour, minute), 10);
};

const getOperatingDay = () => {
  const date = new Date();
  const day = date.getDay();
  let operatingDay;

  switch (day) {
    case 6:
      operatingDay = 'saturday';
      break;
    case 7:
      operatingDay = 'sunday';
      break;
    default:
      operatingDay = 'weekdays';
  }
  return operatingDay;
};

export default function isOperatingHour(hours) {
  let isOperating = true;
  if (hours && hours.length > 0) {
    const hourData = hours.find((hour) => hour.period === getOperatingDay());
    if (hourData) {
      const startHour = hourData.start_hour;
      const endHour = hourData.end_hour;
      const currentTime = getCurrentTime();
      isOperating = startHour <= currentTime && endHour > currentTime;
    }
  }
  return isOperating;
}

export function determineOperatingHour(state) {
  const { node } = state.props;
  if (node.operating24) {
    clearInterval(state.operatingInterval);
  } else {
    state.setState({ isOperating: isOperatingHour(node.operatingHours) });
  }
}
