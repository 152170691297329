/**
 * Local storage utils
 */

let hasStorage = true;
const { localStorage } = window;
let store = {}; // eslint-disable-line import/no-mutable-exports

const PREFIX = 'PisanoCacher::';
const getPrefixedKey = (key) => `${PREFIX}${key}`;

try {
  localStorage.setItem('testingLocalStorage', 'test');
  localStorage.removeItem('testingLocalStorage');
  store.setItem = (key, val) => localStorage.setItem(getPrefixedKey(key), JSON.stringify(val));
  store.getItem = (key) => JSON.parse(localStorage.getItem(getPrefixedKey(key)));
  store.removeItem = (key) => localStorage.removeItem(getPrefixedKey(key));
} catch (e) {
  hasStorage = false;
}

if (!hasStorage) {
  store = (() => {
    let length = 0;
    let store = {};
    return {
      setItem: (key, value) => {
        const prefixedKey = getPrefixedKey(key);
        if (!store[prefixedKey]) length += 1;
        store[prefixedKey] = JSON.stringify(value);
      },
      getItem: (key) => store[getPrefixedKey(key)],
      removeItem: (key) => {
        delete store[getPrefixedKey(key)];
        length -= 1;
      },
      clear: () => {
        store = {};
      },
      key: (i) => Object.keys(store)[i],
      length: () => length,
    };
  })();
}

export default store;
