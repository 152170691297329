import localStorage from '../../../utils/localStorage';

export function handleLinkChannelStore(nodeId, payload = {}) {
  const stores = localStorage.getItem('linkChannels');

  const store = {};
  store[nodeId] = { ...getLinkChannelStore(nodeId), ...payload };
  localStorage.setItem('linkChannels', { ...stores, ...store });

  return getLinkChannelStore(nodeId);
}

export function getLinkChannelStore(nodeId) {
  const stores = localStorage.getItem('linkChannels');
  return stores && stores[nodeId] ? stores[nodeId] : {};
}
