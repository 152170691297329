/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */
import './public-path';
import App from '@pisano/feedback-core/app/components/App';
import ErrorBoundary from '@pisano/feedback-core/app/components/ErrorBoundary';
import { SetFeedbackCoreConfig } from '@pisano/feedback-core/app/config';
import configureStore from '@pisano/feedback-core/app/configureStore';
import Flow from '@pisano/feedback-core/app/containers/Flow/Loadable';
import { SentryConfigure } from '@pisano/feedback-core/app/utils/sentry';
import { createBrowserHistory } from 'history';
import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import ProtectedRoute from './components/ProtectedRoute';
import Chat from './containers/Chat';
import Widget from './containers/Widget';
import { ENV } from './env';
import translations from './translations.json';

import appCss from '@pisano/feedback-core/app/app.scss';
import { Route, Switch } from 'react-router-dom';
import './saga-hooks';
import feedbackWebCss from './styles/feedback-web.scss';

// eslint-disable-next-line no-undef
window.__PSN_BUILD_INFO__ = __PSN_BUILD_INFO__;

SetFeedbackCoreConfig(ENV);
SentryConfigure();

import('@pisano/feedback-core/app/containers/Translate').then(({ configureTranslate }) => {
  configureTranslate({
    translations,
  });
});

// Create redux store with history
const initialState = {};
const history = createBrowserHistory();
const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById('app');

const initGtm = (id) => {
  if (!ENV.GTM_ENABLED) {
    return;
  }
  const script = document.createElement('script');
  script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),
    dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='
    +i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${id}');`;

  const noScript = document.createElement('noscript');
  const iframe = document.createElement('iframe');
  iframe.src = `https://www.googletagmanager.com/ns.html?id=${id}`;
  iframe.height = 0;
  iframe.width = 0;
  iframe.style.cssText = 'display:none;visibility:hidden';
  noScript.appendChild(iframe);

  document.head.appendChild(script);
  document.body.appendChild(noScript);
};

const init = () => {
  const errorWrapper = (p) => <div className="center-absolute">{p.children}</div>;
  const notFoundRender = () => {
    const path = '__NOT_FOUND_PATH__';

    window.location.href = path && !path.includes('NOT_FOUND') ? path : 'https://www.pisano.co/';
    return null;
  };

  render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ErrorBoundary loadCss={appCss} wrapper={errorWrapper}>
          <App>
            <Switch>
              <ProtectedRoute
                component={Flow}
                path="/web_flow_preview"
                loadCss={feedbackWebCss}
                exact
                requiredQueryParams="flow_id"
                componentProps={{
                  defaultPlaceholder: 'webPlaceholder',
                  timeoutEnabled: false,
                  animationsEnabled: false,
                  queryParams: {
                    isPreview: true,
                  },
                }}
              />
              <ProtectedRoute
                component={Flow}
                path="/feedback"
                exact
                requiredQueryParams="node_id"
                componentProps={{
                  defaultPlaceholder: 'webPlaceholder',
                  timeoutEnabled: false,
                  animationsEnabled: false,
                }}
              />
              <ProtectedRoute
                component={Flow}
                path="/web_feedback"
                exact
                requiredQueryParams="node_id"
                componentProps={{
                  defaultPlaceholder: 'webPlaceholder',
                  timeoutEnabled: false,
                  animationsEnabled: false,
                }}
              />
              <ProtectedRoute component={Chat} path="/chat" exact requiredQueryParams={['feedback_id', 'token']} />
              <ProtectedRoute component={Widget} path="/widget" exact requiredQueryParams="code" />
              <Route render={notFoundRender} />
            </Switch>
          </App>
        </ErrorBoundary>
      </ConnectedRouter>
    </Provider>,
    MOUNT_NODE,
  );
};

initGtm(ENV.GTM_ID);
init();

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['@pisano/feedback-core/app/components/App'], () => {
    unmountComponentAtNode(MOUNT_NODE);
    init();
  });
}
