import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import PisanoPowered from '../../images/pisano-powered.png';
import CollectFeedbackLogo from '../../images/collectFeedbackLogo.png';
import { THANK } from '../../containers/FlowState/stateTypes';

export function PisanoPoweredLogo(props) {
  const { languageCode, isThank } = props;

  const url =
    languageCode === 'TR'
      ? 'https://www.pisano.com/tr/pisano-ile-musteri-deneyimi-yonetimine-baslayin'
      : 'https://www.pisano.com/en/get-started-on-customer-experience-management-with-pisano';

  return (
    <a href={url} target="_blank">
      <img
        src={isThank ? CollectFeedbackLogo : PisanoPowered}
        style={{ width: isThank ? '200px' : '120px' }}
        alt={isThank ? 'collectedFeedback' : 'pisano-powered'}
      />
    </a>
  );
}

function isThankPage(state) {
  if (state.flow) {
    if (state.flow.currentState) {
      return state.flow.currentState.style === THANK;
    }
    return true;
  }

  return false;
}

PisanoPoweredLogo.propTypes = {
  languageCode: PropTypes.string,
  isThank: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  languageCode: state.language.currentLanguage.code,
  isThank: isThankPage(state),
});

export default connect(mapStateToProps)(PisanoPoweredLogo);
