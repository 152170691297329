/*
 *
 * ChatRating actions
 *
 */

import { RATE_CHAT } from './constants';

export function rateChat(rate) {
  return {
    type: RATE_CHAT,
    payload: rate,
  };
}
