import { requestJson } from '@pisano/feedback-core/app/utils/request';
import { eventChannel } from 'redux-saga';
import { call, fork, put, select, take, takeEvery } from 'redux-saga/effects';
import { ENV } from '../../env';
import { addTimelineItem, setTimelineItem } from '../Chat/actions';
import { removeFile, updateProgress } from './actions';
import { SEND_MESSAGE } from './constants';

function* progressListener(channel) {
  while (true) {
    const progress = yield take(channel);
    yield put(updateProgress({ progress }));
  }
}

export function* sendMessage({ payload: { message, feedbackId, attachment } }) {
  const state = yield select();
  const id = Math.floor(Math.random() * 1000000);

  // Add a dummy timeline item to show the sending status.
  yield put(
    addTimelineItem({
      id,
      action: 'add_comment',
      actor: 'customer',
      detail: {
        comment: { body: message, created_at: Date.now() },
        customer: state.chat.creator,
      },
      isSending: true,
    }),
  );

  const requestUrl = `${ENV.API_URL}/v1/feedbacks/${feedbackId}/chat_messages`;
  const data = new FormData();

  data.append('body', message);

  const options = {
    method: 'POST',
    body: data,
  };

  let progressChannel;

  if (attachment) {
    data.append('attachment', attachment);

    let progressEmitter;
    progressChannel = eventChannel((emitter) => {
      progressEmitter = emitter;
      return () => {};
    });
    let lastEmittedProgress = -1;

    options.progress = (e) => {
      const progress = Math.round((e.loaded / e.total) * 100);
      if (e.loaded === e.total) {
        progressEmitter(-1);
        removeFile();
      } else if (progress !== lastEmittedProgress) {
        lastEmittedProgress = progress;
        progressEmitter(progress);
      }
    };
  }

  try {
    if (attachment) yield fork(progressListener, progressChannel);
    yield call(requestJson, requestUrl, options);
  } catch (err) {
    yield put(
      setTimelineItem(id, {
        isSending: false,
        hasError: true,
      }),
    );
  }
}

export default function* defaultSaga() {
  yield takeEvery(SEND_MESSAGE, sendMessage);
}
