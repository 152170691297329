import { configureSaga } from '@pisano/feedback-core/app/containers/Flow/sagas/changeStateSaga';
import { CHAT } from '@pisano/feedback-core/app/containers/FlowState/stateTypes';
import { push } from 'react-router-redux';
import { delay } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { openChat } from '../containers/Widget/actions';

function* postCreateFeedbackHook(response, state) {
  const isChat = state.flow.currentState.style === CHAT;

  if (response.id && isChat) {
    yield call(delay, 2 * 1000);
    // If we are inside a widget, let the widget handle the chat page.
    if (state.widget) {
      yield put(openChat({ feedbackId: response.id, token: response.chat_token_string }));
    } else {
      yield put(push(`/chat?feedback_id=${response.id}&token=${response.chat_token_string}`));
    }
  }
}

configureSaga.configureHooks({
  postCreateFeedbackHook,
});
