import { requestJson, requestText } from '@pisano/feedback-core/app/utils/request';
import last from 'lodash/last';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { ENV } from '../../env';
import { RATE_CHAT } from '../ChatRating/constants';
import { rateChatSaga } from '../ChatRating/saga';
import { chatDetailLoaded, chatDetailLoadingError, setLoading } from './actions';
import { GET_CHAT_DETAIL, SEND_ARCHIVE_FEEDBACK } from './constants';
import chatCss from './styles/chat.scss';

function simplifyNode(node) {
  return {
    name: node.name,
    logo_url: node.logo_url,
    isOperating: node.operating,
    operating24: node.operating_24_7,
    operatingHours: node.operating_hours,
  };
}

export function* getChatDetail({ payload: { feedbackId, token } }) {
  yield put(setLoading(true));
  const requestUrl = `${ENV.API_URL}/v1/feedbacks/${feedbackId}/chat?token=${token}`;
  try {
    const chatDetail = yield call(requestJson, requestUrl);
    const state = yield select();

    chatDetail.node = simplifyNode(chatDetail.node);
    chatDetail.token = token;

    if (state.widget) {
      chatDetail.css = yield call(requestText, chatCss);
    }
    if (chatDetail.chat_rating_enabled && chatDetail.chat_ratings.length > 0) {
      const lastChatRating = chatDetail.chat_ratings[0];
      const lastComment = last(chatDetail.timeline_items.filter((item) => item.actor === 'customer')) || {};
      if (lastChatRating.created_at > lastComment.created_at) {
        chatDetail.isRated = true;
        chatDetail.selectedRating = lastChatRating.rating;
      }
    }
    yield put(chatDetailLoaded(chatDetail));
  } catch (err) {
    if (err.response) {
      // XHR error
      yield put(chatDetailLoadingError(JSON.parse(err.response)));
    } else {
      throw err;
    }
  }
}

export function* sendArchiveFeedback() {
  const state = yield select();

  const requestUrl = `${ENV.API_URL}/v1/feedback/${state.chat.id}/archive`;

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ token: state.chat.token }),
  };

  try {
    yield call(requestJson, requestUrl, options);
  } catch (err) {
    throw err;
  }
}

export default function* defaultSaga() {
  yield all([
    takeLatest(GET_CHAT_DETAIL, getChatDetail),
    takeLatest(RATE_CHAT, rateChatSaga),
    takeLatest(SEND_ARCHIVE_FEEDBACK, sendArchiveFeedback),
  ]);
}
