/*
 *
 * ChatInput constants
 *
 */

export const SEND_MESSAGE = 'app/ChatInput/SEND_MESSAGE';
export const SELECT_FILE = 'app/ChatInput/SELECT_FILE';
export const REMOVE_FILE = 'app/ChatInput/REMOVE_FILE';
export const UPDATE_PROGRESS = 'app/ChatInput/UPDATE_PROGRESS';
