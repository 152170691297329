import queryString from 'query-string';
import { delay } from 'redux-saga';
import { all, call, put, select } from 'redux-saga/effects';
import { NEXT_PAGE, SUBMIT } from '../../../components/FlowStateElement/elementTypes';
import { MATRIX } from '../../../constants/questionTypes';
import sagaConfig from '../../../utils/configureSaga';
import localStorage from '../../../utils/localStorage';
import { handleStatePageClass, handleStatePageClassClear } from '../../../utils/statePageClass';
import DEFAULT_THANK_STATE from '../../FlowState/defaultThankState';
import { CHAT, THANK } from '../../FlowState/stateTypes';
import {
  clearResponsesOnState,
  enqueueFeedback,
  processFeedbackQueue,
  sendChannelQuotaLog,
  sendPostMessage,
  setFeedbackResponse,
  setIsSubmitError,
  setIsSubmitting,
  setPreventMultipleFeedback,
  setQuestion,
  setupCurrentState,
} from '../actions';
import { DEVICE_ID, FLOW_ANSWERS } from '../constants';
import { handleLinkChannelStore } from '../helpers/linkChannelStores.js';
import getFeedbackFromState from '../utils/getFeedbackFromState';
import { maskPersonalData } from '../utils/hidePersonalData';
import sendFeedback from '../utils/sendFeedback';
import { removeAppliedTriggers } from './questionSaga';
import { removeIncompleteSurvey } from '../utils/incompleteSurveys.js';
import { handleWidgetStore } from '../../../../../feedback-web/containers/Widget/helpers/widgetStores.js';

export const configureSaga = sagaConfig();
configureSaga.configureHooks({ postCreateFeedbackHook: null });

export function isQuestionsAnswered(state, hasNextStateButton) {
  const { currentState, questions } = state.flow;
  for (let i = 0; i < currentState.elements.length; i++) {
    const elementId = currentState.elements[i];
    const element = state.flow.elements[elementId];
    const question = questions[elementId];
    const { isVisible } = state.flow.elements[elementId];
    if (!question || !isVisible) continue; // eslint-disable-line no-continue
    if (!question.isValid || (question.isRequired && (!question.response || !question.response.length))) {
      return false;
    }
    if (!hasNextStateButton && !question.response) {
      return false;
    }
    if (
      question.isMultiSelect &&
      question.minSelectionCount &&
      question.response.length &&
      question.response.length < question.minSelectionCount
    ) {
      return false;
    }

    if (question.isRequired && element.question.style === MATRIX) {
      let hasUnansweredStatement = false;

      element.question.statements.forEach((statement) => {
        if (!question.response.some((response) => response.statementId === statement.id)) {
          hasUnansweredStatement = true;
        }
      });

      if (hasUnansweredStatement) {
        return false;
      }
    }
  }
  return true;
}

function questionMatchesTransitionRule(question, rule) {
  if (!question) return false;
  if (question.id !== rule.elementId) {
    return false;
  }

  const { response } = question;

  if (!response) return false;

  const { operator, value, optionIds, statementId } = rule;

  switch (operator) {
    case 'equal':
      return response === value;

    case 'contains':
      return response.includes(value);

    case 'not-contains':
      return !response.includes(value);

    case 'regex':
      return new RegExp(value, 'i').test(response);

    default:
      return optionIds.some((optionId) => matchesRule(response, optionId, statementId));
  }
}

function matchesRule(response, optionId, statementId) {
  if (!Array.isArray(response)) {
    return response === optionId;
  }

  return response.some((res) => {
    if (typeof res === 'string') {
      return res === optionId;
    }
    const hasStatementOptionId = 'statementOptionId' in res;

    if (hasStatementOptionId && statementId !== undefined) {
      return res.statementOptionId === optionId && res.statementId === statementId;
    }
    return res.statementOptionId === optionId;
  });
}

function findIfStateHasTransition(state) {
  const { questions, currentState } = state.flow;
  // INFO: you can get this code back
  // .filter((id) => elements[id].isVisible) // Only visible elements
  const allQuestions = Object.keys(questions).reduce((xs, id) => ({ ...xs, [id]: questions[id] }), {});
  const matchingTransitions = state.flow.transitions
    .filter((transition) => transition.fromStateId === currentState.id)
    .filter((transition) =>
      transition.rules
        .map((rule) => questionMatchesTransitionRule(allQuestions[rule.elementId], rule))
        .reduce((prev, cur) => prev && cur, true),
    );

  // TODO: What if there are multiple matching transitions?
  // Currently return the first one
  return matchingTransitions[0];
}

export function findNextStateInfo(state, source) {
  const transition = findIfStateHasTransition(state);
  let nextStateId =
    (transition && transition.toStateId) || state.flow.currentState.nextStateId || DEFAULT_THANK_STATE.state.id;
  let nextState = state.flow.states[nextStateId];

  if (source === SUBMIT) {
    // If the source is submit, immediately go to the thank state.
    // If there is no custom thank state, go to the default one.
    while (nextState.style !== THANK && nextState.style !== CHAT) {
      nextStateId = nextState.nextStateId || DEFAULT_THANK_STATE.state.id;
      nextState = state.flow.states[nextStateId];
    }
  }

  return nextState;
}

export function* nextStateSaga({ payload: { stateChangeParams } }) {
  const { source, hasNextStateButton } = stateChangeParams;
  const state = yield select();
  if (isQuestionsAnswered(state, hasNextStateButton)) {
    yield call(delay, 250);
    const nextState = findNextStateInfo(state, source);

    const nextStateIsThankOrChat = nextState.style === THANK || nextState.style === CHAT;
    if (state.flow.isSingleElementPreview && nextStateIsThankOrChat) {
      return;
    }

    // If next state is thank or chat, or next state request comes from a submit button,
    // create the feedback after setting up the current state.
    if (!state.flow.isPreview && (nextStateIsThankOrChat || source === SUBMIT)) {
      if (state.flow.isSubmitting) return;

      yield put(setIsSubmitting(true));
      // If error message is shown, clear it. Before requesting to create feedback.
      yield put(setIsSubmitError(null));
      const httpResponse = yield createFeedback(nextState);

      if (httpResponse) {
        let body = null;
        try {
          body = JSON.parse(httpResponse.responseText);
        } catch {
          body = {};
        }
        // Only do transition to next state if feedback creation is successful
        // TODO, currently no error message is shown to the user with a notification or toast.
        if (httpResponse.status === 200) {
          nextState.previousState = state.flow.currentState;
          yield put(setupCurrentState(nextState));

          const hooks = configureSaga.getHooks();
          // If it's a chat state in web, go to the chat.
          if (hooks.postCreateFeedbackHook) {
            yield* hooks.postCreateFeedbackHook(body, yield select());
          }

          handleStatePageClass(nextState.style);
        } else if (httpResponse.status === 400 && body.code === 13045) {
          yield put(setPreventMultipleFeedback(true));
        } else {
          // Submit error
          yield put(setIsSubmitError(body));
          handleStatePageClassClear();
        }
      }

      yield put(setIsSubmitting(false));
    } else {
      nextState.previousState = state.flow.currentState;
      yield put(setupCurrentState(nextState));
      handleStatePageClass(nextState.style);
    }
  } else {
    if (source !== NEXT_PAGE && source !== SUBMIT) {
      return;
    }
    const { currentState, questions } = state.flow;
    for (let i = 0; i < currentState.elements.length; i += 1) {
      const elementId = currentState.elements[i];
      const question = questions[elementId];
      if (
        question &&
        question.isRequired &&
        (question.response === null || (Array.isArray(question.response) && question.response.length === 0))
      ) {
        yield put(setQuestion({ questionId: elementId, hasRequiredError: true }));
      }
    }
  }
}

export function* previousStateSaga() {
  const state = yield select();
  const { previousState } = state.flow.currentState;
  for (let i = 0; i < state.flow.currentState.elements.length; i++) {
    const elementId = state.flow.currentState.elements[i];
    // Checking if there is an elementId in preloadstate.flow.preloadedResponses.
    // Example: state.flow.preloadedResponses [ {questionId: '3c7a2ec9-24a5-4255-8f66-32271d3fad28', response: '123123gh'}, {questionId: '3c7a2ec9-24a5-4255-8f66-32271d3fad28', response: '123123gh'} ]
    if (state.flow.preloadedResponses && state.flow.elements[elementId] && !state.flow.elements[elementId].isVisible) {
      const element = state.flow.preloadedResponses.find((item) => item.questionId === elementId);
      if (!element) {
        yield removeAppliedTriggers(state, elementId);
      }
    } else {
      yield removeAppliedTriggers(state, elementId);
    }
  }
  yield all([put(clearResponsesOnState({ state: state.flow.currentState })), put(setupCurrentState(previousState))]);
}

export function removeFlowAnswersFromLocalStorage(nodeId) {
  // when user submit the flow, remove flow answers from localStorage
  localStorage.removeItem(FLOW_ANSWERS.concat(nodeId));
}

function* createFeedback(nextState) {
  const nextStateIsThank = nextState.style === THANK;
  const state = yield select();
  const feedback = getFeedbackFromState(state);

  // Send the feedback or enqueue it if it fails.
  let response = {};
  let body = {};
  try {
    maskPersonalData(feedback);
    response = yield call(sendFeedback, feedback, true);
    body = JSON.parse(response.responseText);
    yield put(processFeedbackQueue());
    yield put(setFeedbackResponse(body));

    // When is_internal_feedback: true, that means the feedback is coming from Pisano dashboard.
    // So if it's internal feedback, don't add delay before throw event.
    // Same usage is also present in vision and can be searched as is_internal_feedback.
    const parsed = queryString.parse(state.route.location?.search);
    const isInternalFeedback = parsed && parsed.is_internal_feedback && parsed.is_internal_feedback === 'true';

    yield put(
      sendPostMessage({
        type: 'PSN_END_OF_FLOW',
        data: {
          flow_id: state.flow.id,
          flow_name: state.flow.name,
          feedback_id: body.id,
          has_chat: state.flow.currentState.style === CHAT,
        },
      }),
    );

    yield put(
      sendPostMessage({
        type: 'PSN_FEEDBACK_SUBMITTED',
        delay: !isInternalFeedback && nextStateIsThank ? 3000 : 0,
        data: {
          flow_id: state.flow.id,
          flow_name: state.flow.name,
          feedback_id: body.id,
          has_chat: state.flow.currentState.style === CHAT,
        },
      }),
    );

    removeFlowAnswersFromLocalStorage(state.node.id);
    removeIncompleteSurvey(body.id);

    if (state.node.preventMultipleFeedback) {
      handleLinkChannelStore(state.node.id, { firstNow: new Date(), isLinkChannelDisplayedBefore: true });
    }

    if (state.widget?.preventMultipleFeedback) {
      handleWidgetStore(state.node.id, { lastFeedbackSubmitDate: new Date(), isWidgetDisplayedBefore: true });
    }

    if (state.node.channelQuota) {
      const { isActive, type } = state.node.channelQuota;
      if (isActive && type === 'submit') {
        yield put(sendChannelQuotaLog({ type: 'submitted' }));
      }
    }
  } catch (err) {
    response = err.response;

    // If there is no internet connection, save the feedback data to be sent in localStorage and then try to send it if online
    if (!window.navigator.onLine) {
      feedback.created_offline = true;
      const device_id = localStorage.getItem(DEVICE_ID);
      feedback.device_id = device_id;
      yield put(enqueueFeedback(feedback));
    }

    if (response && response.status !== 200) {
      return response;
    }
  }

  return response;
}
