/**
 *
 * Asynchronously loads the component for Translate
 *
 * HACK: This actually shouldn't be an async component. We've introduced this just to not load the big
 * emojione dependency in Widget init.
 *
 */

import Loadable from 'react-loadable';

export default Loadable({
  loader: () => import('./index'),
  loading: () => null,
});
