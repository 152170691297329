import { requestJson } from '@pisano/feedback-core/app/utils/request';
import { call, put, takeEvery } from 'redux-saga/effects';
import { ENV } from '../../env';
import { chatRated, chatRatingError } from '../Chat/actions';
import { RATE_CHAT } from './constants';

export function* rateChatSaga({ payload: { feedbackId, rate } }) {
  const requestUrl = `${ENV.API_URL}/v1/feedbacks/${feedbackId}/chat_ratings`;
  const data = {
    rating: rate,
  };
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  try {
    const response = yield call(requestJson, requestUrl, options);
    yield put(chatRated(response));
  } catch (err) {
    yield put(chatRatingError(err));
  }
}

export default function* defaultSaga() {
  yield takeEvery(RATE_CHAT, rateChatSaga);
}
