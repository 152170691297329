import { put } from 'redux-saga/effects';
import { setLanguage } from '../../../reducers';

export default function* setDefaultLanguage(state, queryParamLang) {
  // If the language is already set, do nothing.
  if (state.language.currentLanguage.id) return;

  const { languages } = state.flow;
  const flowHasLanguages = languages && languages.length > 0;
  if (state.flow.id && state.node.id && flowHasLanguages) {
    // Order of preference:
    // 1*. If we're in a widget, language from config
    // 1. Language from query params
    // 2. Default language of the node
    // 3. Default language of the flow
    // 4. First language of the flow
    const defaultLanguage =
      (state.widget &&
        state.language.currentLanguage.code &&
        languages.find((l) => l.code === state.language.currentLanguage.code.toUpperCase())) ||
      languages.find((l) => l.code === (queryParamLang && queryParamLang.toUpperCase())) ||
      languages.find((l) => l.id === state.node.defaultLanguageId) ||
      languages.find((l) => l.id === state.flow.defaultLanguageId) ||
      languages[0];
    yield put(setLanguage(defaultLanguage));
  }
}
