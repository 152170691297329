import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import fileIcon from './images/file.png';
import closeIcon from './images/close.png';

function FileUploadProgress({ progress, file, url, cancel, isLoading }) {
  const fileName = useMemo(() => {
    if (!file) {
      const urlWithoutParams = url.split('?')[0]; // Remove query parameters
      const fileName = urlWithoutParams.substring(urlWithoutParams.lastIndexOf('/') + 1);

      return fileName;
    }

    return file.name;
  }, [file]);

  const mimeType = useMemo(() => {
    if (!file) {
      const fileExtension = fileName.split('.').pop();

      let mime;
      if (fileExtension.toLowerCase() === 'pdf') {
        mime = 'application/pdf';
      } else {
        mime = `image/${fileExtension}`;
      }

      return mime;
    }

    return file.type;
  }, [file]);

  const renderLoadingState = () => (
    <div className="file-upload-progress">
      <div className="file-upload-progress__file-data">
        <div className="text-overflow-ellipsis">{file?.name}</div>
        <div className="file-upload-progress__loading-bar">
          <div style={{ width: `${progress}%` }} />
        </div>
      </div>

      <div className="file-upload-progress__close-button" role="button" tabIndex={0} onClick={() => cancel()}>
        <img src={closeIcon} alt="" />
      </div>
    </div>
  );

  const renderLoadedState = () => (
    <div className="file-upload-progress">
      <img src={fileIcon} alt="" className="file-upload-progress__file-icon" />

      <div className="file-upload-progress__file-data">
        <div className="text-overflow-ellipsis">{fileName}</div>
        <div className="is-muted-text">
          {file?.size} {mimeType}
        </div>
      </div>

      <div className="file-upload-progress__close-button" role="button" tabIndex={0} onClick={() => cancel()}>
        <img src={closeIcon} alt="" />
      </div>
    </div>
  );

  return isLoading ? renderLoadingState() : renderLoadedState();
}

FileUploadProgress.propTypes = {
  cancel: PropTypes.func.isRequired,
  file: PropTypes.shape({
    name: PropTypes.string,
    size: PropTypes.string,
    type: PropTypes.string,
  }),
  isLoading: PropTypes.bool.isRequired,
  url: PropTypes.string,
  progress: PropTypes.number.isRequired,
};

export default FileUploadProgress;
