/**
 *
 * WidgetButton
 *
 */

import Translate from '@pisano/feedback-core/app/containers/Translate/Loadable';
import CaretRight from '@pisano/feedback-core/app/images/caret-right.svg';
import Frame from '@pisano/react-frame-component';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { closeWidget, openWidget } from '../Widget/actions';
import OpenIcon from './images/open-icon.svg';

class WidgetButton extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = { height: 0 };
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      const { isModalWidget } = this.props;
      if (!isModalWidget || !this.button) return;
      const height = this.button.scrollHeight;
      if (height !== this.state.height) {
        this.setState({ height });
      }
    }, 500);
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  renderPlainWidgetButton(onClick) {
    const { isWidgetOpen, fontColor } = this.props;
    const closeIconClass = classNames('widget-button__icon', 'widget-button__icon--close', {
      'psn-widget-invisible': !isWidgetOpen,
    });
    const openIconClass = classNames('widget-button__icon', 'widget-button__icon--open', {
      'psn-widget-invisible': isWidgetOpen,
    });
    return (
      <div className="widget-button" onClick={onClick} role="button" tabIndex={0} style={{ color: fontColor }}>
        <div className={closeIconClass}>
          <CaretRight style={{ transform: 'rotate(90deg)' }} />
        </div>
        <div className={openIconClass}>
          <OpenIcon alt="Open" />
        </div>
      </div>
    );
  }

  renderModalWidgetButton(onClick) {
    const { modalToggleText, fontColor } = this.props;
    return (
      <div
        className="widget-button"
        onClick={onClick}
        role="button"
        tabIndex={0}
        ref={(el) => {
          this.button = el;
        }}
      >
        <Translate
          component="span"
          className="widget-text"
          style={{ color: fontColor }}
          message={modalToggleText || 'widgetModalToggleText'}
        />
      </div>
    );
  }

  render() {
    const {
      size,
      margin,
      backgroundColor,
      isWidgetOpen,
      openWidget,
      closeWidget,
      isModalWidget,
      initialFrameContent,
      className,
      radius,
    } = this.props;
    let style = { backgroundColor };
    if (isModalWidget) {
      style = {
        ...style,
        height: this.state.height,
        bottom: `${100 - margin}%`,
        transform: `translateY(${100 - margin}%)`,
        borderBottomLeftRadius: radius,
        borderTopLeftRadius: radius,
      };
    } else {
      style = {
        ...style,
        right: margin,
        bottom: margin,
      };
    }

    const toggleWidget = () => (isWidgetOpen ? closeWidget() : openWidget());
    return (
      <Frame
        initialContent={initialFrameContent}
        style={style}
        frameRef={(node) => {
          if (node && !isModalWidget) {
            node.style.setProperty('width', `${size}px`, 'important');
            node.style.setProperty('height', `${size}px`, 'important');
          }
        }}
        id="psn-widget-button-frame"
        className={className}
        title="Pisano Widget Button"
        allowFullScreen
      >
        {isModalWidget ? this.renderModalWidgetButton(toggleWidget) : this.renderPlainWidgetButton(toggleWidget)}
      </Frame>
    );
  }
}

WidgetButton.propTypes = {
  size: PropTypes.number.isRequired,
  margin: PropTypes.number.isRequired,
  fontColor: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  isWidgetOpen: PropTypes.bool.isRequired,
  isModalWidget: PropTypes.bool.isRequired,
  openWidget: PropTypes.func.isRequired,
  closeWidget: PropTypes.func.isRequired,
  modalToggleText: PropTypes.object,
  initialFrameContent: PropTypes.string,
  className: PropTypes.string,
  radius: PropTypes.number,
};

function mapStateToProps(state) {
  return {
    size: state.widget.size,
    margin: state.widget.margin,
    fontColor: state.widget.fontColor,
    backgroundColor: state.widget.secondaryColor,
    isWidgetOpen: state.widget.isOpen,
    isModalWidget: state.widget.isModal,
    modalToggleText: state.widget.modalToggleText,
    radius: state.widget.radius,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    openWidget: () => dispatch(openWidget()),
    closeWidget: () => dispatch(closeWidget()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WidgetButton);
