/**
 *
 * Chat
 *
 */

import Loading from '@pisano/feedback-core/app/components/Loading';
import { FILE } from '@pisano/feedback-core/app/constants/questionTypes';
import { CHAT } from '@pisano/feedback-core/app/containers/FlowState/stateTypes';
import Translate from '@pisano/feedback-core/app/containers/Translate/Loadable';
import CaretRight from '@pisano/feedback-core/app/images/caret-right.svg';
import CloseIcon from '@pisano/feedback-core/app/images/close.svg';
import { customContentInjector } from '@pisano/feedback-core/app/utils/customContentInjector';
import { createError, ReportedError, UnreportedError } from '@pisano/feedback-core/app/utils/error';
import injectReducer from '@pisano/feedback-core/app/utils/injectReducer';
import injectSaga from '@pisano/feedback-core/app/utils/injectSaga';
import scrollToBottom from '@pisano/feedback-core/app/utils/scrollToBottom';
import { handleStatePageClass, handleStatePageClassClear } from '@pisano/feedback-core/app/utils/statePageClass';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { ArchiveModal } from '../../components/ArchiveModal';
import ChatMessage from '../../components/ChatMessage';
import ChatInput from '../ChatInput';
import ChatRating from '../ChatRating';
import { archiveModal, getChatDetail, sendArchiveFeedback, setTimelineItems, updateRateChatOptions } from './actions';
import { destroySocket, setupSocket } from './configureSocket';
import reducer from './reducer';
import saga from './saga';
import cssUrl from './styles/chat.scss';

export class Chat extends React.PureComponent {
  /**
   * Groups the successive timeline items belonging to the same actor.
   *
   * Example: Turns
   * ```
   * [
   *  item1{actor='customer'}, item2{actor='customer'}, item3{actor='user'}, item4{actor='customer'}
   * ]
   * ```
   * into
   * ```
   * [
   *  [ item1{actor='customer'}, item2{actor='customer'} ],
   *  [ item3{actor='user'} ],
   *  [ item4{actor='customer'} ]
   * ]
   * ```
   *
   * HACK: This method also simplifies the timeline items data, which should be done in the API or saga.
   *
   * @static
   * @param {any} timelineItems A list of timeline items
   * @returns
   * @memberof Chat
   */
  static getSenderGroupedResponses(timelineItems) {
    return (
      timelineItems
        .filter((item) => item.action === 'add_comment')
        .map((item) => ({
          id: item.id,
          isSelf: item.actor === 'customer',
          body: item.detail.comment.body,
          attachment: item.detail.comment.attachment,
          createdAt: item.detail.comment.created_at,
          sender: item.detail.user || item.detail.customer,
          hasError: item.hasError || false,
          isSending: item.isSending || false,
        }))
        // Group continuous elements by `isSelf`
        .reduce((items, item) => {
          if (items.length === 0) return [[item]];
          const lastGroup = items[items.length - 1];
          if (lastGroup[0].isSelf === item.isSelf) {
            lastGroup.push(item);
          } else {
            items.push([item]);
          }
          return items;
        }, [])
    );
  }

  /**
   * Transforms the given responses to a comment body in the format:
   * ```
   * <span>Translated question body</span> <strong>response</strong>
   * <br/>
   * <span>Translated question body 2</span> <strong>response 2</strong>
   * ...
   * ```
   *
   * @static
   * @param {any} responses
   * @returns {any} A set of React elements that forms the first comment in the feedback chat.
   * @memberof Chat
   */
  static commentBodyFromResponses(responses) {
    let key = 0;
    return responses.reduce((body, { response, question }) => {
      // Push a line break between separate answers
      if (body.length !== 0) {
        body.push(<br key={key++} />);
      }
      const questionEl = (
        <Translate component="span" key={key++} message={question.body} parseMarkdown sanitizeMarkdown />
      );
      const responseEl =
        question.style === FILE ? (
          <Translate component="a" className="link" href={response} target="_blank" message="clickToOpen" />
        ) : (
          response.trim()
        );
      return body.concat([questionEl, <strong key={key++}>{responseEl}</strong>]);
    }, []);
  }

  constructor(props, context) {
    super(props, context);
    this.state = { containerPaddingBottom: 0 };
  }

  componentDidMount() {
    const { getChatDetail, setTimelineItems, updateRateChatOptions, insideWidget } = this.props;
    const feedbackId = this.getFeedbackId();
    const token = this.getToken();
    getChatDetail(feedbackId, token);
    setupSocket(setTimelineItems, updateRateChatOptions, feedbackId);
    scrollToBottom(this.chatTimeline);
    handleStatePageClass(CHAT);
    // If inside widget, watch the chat input height to increase the chat timeline margin.
    if (insideWidget) {
      this.chatInputHeightInterval = setInterval(() => {
        if (!this.chatInput) {
          this.chatInput = (this.context.document || document).getElementById('chat-input');
          this.chatInput = this.chatInput && this.chatInput.parentNode;
        }
        if (this.chatInput) {
          const height = this.chatInput.scrollHeight;
          if (Math.abs(height - this.state.containerPaddingBottom) > 1) {
            // -3 comes from the shadow.
            this.setState({ containerPaddingBottom: height - 3 });
          }
        }
      }, 1000 / 20);
    }
  }

  componentDidUpdate() {
    const { onUpdate } = this.props;
    scrollToBottom(this.chatTimeline);
    if (typeof onUpdate === 'function') {
      onUpdate();
    }
  }

  componentWillUnmount() {
    if (this.chatInputHeightInterval) {
      clearInterval(this.chatInputHeightInterval);
    }
    destroySocket(this.getFeedbackId());
    handleStatePageClassClear();
  }

  onClickCloseButton() {
    window.parent.postMessage(
      JSON.stringify({
        type: 'PSN_WEB_WIDGET_CLOSED',
      }),
      '*',
    );
  }

  getFeedbackId() {
    if (!this.feedbackId) {
      const queryParams = this.props.queryParams || queryString.parse(this.props.location.search);
      if (!queryParams.feedback_id) {
        // If we're inside a widget and there is no feedback id, it's a system error.
        const { insideWidget } = this.props;
        const errorMessage = 'No feedback id in the chat query params.';
        if (insideWidget) {
          throw createError(ReportedError, 'systemError', errorMessage);
        }
        throw createError(UnreportedError, 'wrongUrlError', errorMessage);
      } else {
        this.feedbackId = queryParams.feedback_id;
      }
    }
    return this.feedbackId;
  }

  getToken() {
    if (!this.token) {
      const queryParams = this.props.queryParams || queryString.parse(this.props.location.search);
      if (!queryParams.token) {
        // If we're inside a widget and there is no feedback id, it's a system error.
        const { insideWidget } = this.props;
        const errorMessage = 'No chat token in the chat query params.';
        if (insideWidget) {
          throw createError(ReportedError, 'systemError', errorMessage);
        }
        throw createError(UnreportedError, 'wrongUrlError', errorMessage);
      } else {
        this.token = queryParams.token;
      }
    }
    return this.token;
  }

  renderSenderInfo(sender) {
    let senderInfo;
    if (!sender.name) {
      senderInfo = sender.email || sender.phone_number;
      if (!senderInfo) {
        return <Translate className="sender-info" message="unknown" />;
      }
    } else if (sender.nickname) {
      senderInfo = sender.nickname;
    } else if (!sender.surname) {
      senderInfo = sender.name;
    } else {
      senderInfo = `${sender.name} ${sender.surname}`;
    }
    return <div className="sender-info">{senderInfo}</div>;
  }

  renderSenderLabel(isSelf, sender) {
    const avatarStyle = {
      background: sender.avatar ? `url(${sender.avatar})` : `#${sender.avatar_color}`,
    };
    return (
      <div className="sender-label">
        {isSelf ? <Translate className="sender-info" message="you" /> : this.renderSenderInfo(sender)}
        <div className="sender-avatar" style={avatarStyle}>
          {!sender.avatar && (sender.name ? sender.name.trim()[0].toUpperCase() : 'X')}
        </div>
      </div>
    );
  }

  renderQueueLabel(isSelf, sender, account) {
    const avatarStyle = {
      background: account.logo_url ? `url(${account.logo_url})` : `#${sender.avatar_color}`,
    };
    return (
      <div className="sender-label">
        {isSelf ? <Translate className="sender-info" message={account.name} /> : this.renderSenderInfo(sender)}
        <div className="sender-avatar" style={avatarStyle}>
          {!account.logo_url && (account.name ? account.name.trim()[0].toUpperCase() : 'P')}
        </div>
      </div>
    );
  }

  renderHeadTags() {
    const { insideWidget, css } = this.props;

    if (insideWidget) return <style type="text/css">{css}</style>;

    customContentInjector({ cssUrl, name: 'chat' });
  }

  render() {
    const {
      chatDetailLoadError,
      feedbackResponses,
      insideWidget,
      creator,
      createdAt,
      node,
      timelineItems,
      chatRatingEnabled,
      queueBody,
      status,
      account,
      loading,
      isPisanoAgent,
      preventUpload,
      isArchiveModal,
      archiveModal,
      sendArchiveFeedback,
    } = this.props;
    if (chatDetailLoadError) {
      throw createError(ReportedError, 'chatDetailLoadError', chatDetailLoadError.message);
    }

    if (loading) {
      return (
        <div className="center-absolute">
          <Loading />
        </div>
      );
    }

    const feedbackId = this.getFeedbackId();
    const responsesBody = Chat.commentBodyFromResponses(feedbackResponses);
    const senderGroupedResponses = Chat.getSenderGroupedResponses(timelineItems);
    const style = { display: 'none', paddingBottom: this.state.containerPaddingBottom };
    const getResponseGroupClass = (responseGroup) =>
      classNames('sender-action-group', {
        left: !responseGroup[0].isSelf,
        right: responseGroup[0].isSelf,
      });

    return (
      <div className="chat-container" style={style}>
        {isArchiveModal && <ArchiveModal hideFn={archiveModal} sendFn={sendArchiveFeedback} />}
        <div
          className="chat-timeline"
          ref={(el) => {
            this.chatTimeline = el;
          }}
        >
          {!insideWidget && (
            <div className="chat-header">
              <div className="chat-logo">
                {node.logo_url && <img height="48" alt={node.name} src={node.logo_url} />}
              </div>
              <div className="chat-header-right">
                {status === 'open' && (
                  <div className="action-button" role="button" tabIndex={0} onClick={() => archiveModal()}>
                    <CloseIcon className="button-icon" alt="Close" />
                  </div>
                )}

                {isPisanoAgent && (
                  <div
                    className="action-button close-button"
                    onClick={() => this.onClickCloseButton()}
                    role="button"
                    tabIndex={0}
                  >
                    <CaretRight className="button-icon" style={{ transform: 'rotate(90deg)' }} />
                  </div>
                )}
              </div>
              <hr className="chat-header-divider" />
            </div>
          )}

          {/* Render the feedback responses. */}
          {status === 'queued' ? (
            <div className="sender-action-group right">
              {this.renderQueueLabel(true, creator, account)}
              <ChatMessage body={responsesBody} createdAt={createdAt} status={status} queueBody={queueBody} />
            </div>
          ) : (
            <div className="sender-action-group right">
              {this.renderSenderLabel(true, creator)}
              <ChatMessage body={responsesBody} createdAt={createdAt} status={status} />
            </div>
          )}
          {/* Render the chat messages. */}
          {senderGroupedResponses.map((responseGroup) => (
            <div className={getResponseGroupClass(responseGroup)} key={responseGroup[0].id}>
              {this.renderSenderLabel(responseGroup[0].isSelf, responseGroup[0].sender)}
              {responseGroup.map((response) => (
                <ChatMessage
                  key={response.id}
                  body={response.body}
                  attachment={response.attachment}
                  createdAt={response.createdAt}
                  hasError={response.hasError}
                  isSending={response.isSending}
                  showTicks={response.isSelf}
                  status={status}
                />
              ))}
            </div>
          ))}
          {chatRatingEnabled && (status === 'closed' || status === 'archived') && (
            <ChatRating id={feedbackId} node={node} />
          )}
        </div>
        <ChatInput feedbackId={feedbackId} node={node} status={status} preventUpload={preventUpload} />
        {this.renderHeadTags()}
      </div>
    );
  }
}

Chat.propTypes = {
  location: PropTypes.object,
  node: PropTypes.object.isRequired,
  creator: PropTypes.object.isRequired,
  createdAt: PropTypes.string.isRequired,
  timelineItems: PropTypes.array.isRequired,
  feedbackResponses: PropTypes.array.isRequired,
  getChatDetail: PropTypes.func.isRequired,
  updateRateChatOptions: PropTypes.func.isRequired,
  setTimelineItems: PropTypes.func.isRequired,
  archiveModal: PropTypes.func.isRequired,
  chatDetailLoadError: PropTypes.object,
  chatRatingEnabled: PropTypes.bool,
  status: PropTypes.string,
  loading: PropTypes.bool,
  queueBody: PropTypes.object,
  account: PropTypes.object,
  queryParams: PropTypes.object, // use if you are loading Chat from another component
  insideWidget: PropTypes.bool,
  css: PropTypes.string,
  onUpdate: PropTypes.func,
  isPisanoAgent: PropTypes.bool,
  preventUpload: PropTypes.bool,
  isArchiveModal: PropTypes.bool,
  sendArchiveFeedback: PropTypes.func.isRequired,
};

Chat.defaultProps = {
  location: {
    search: '',
  },
};

Chat.contextTypes = {
  document: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    chatDetailLoadError: state.chat.error,
    node: state.chat.node,
    creator: state.chat.creator,
    feedbackResponses: state.chat.responses,
    createdAt: state.chat.created_at,
    timelineItems: state.chat.timeline_items,
    css: state.chat.css,
    chatRatingEnabled: state.chat.chat_rating_enabled,
    status: state.chat.status,
    queueBody: state.chat.chat_queue_body,
    account: state.chat.account,
    loading: state.chat.loading,
    isPisanoAgent: state.chat.isPisanoAgent,
    preventUpload: state.chat.prevent_upload,
    isArchiveModal: state.chat.isArchiveModal,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    getChatDetail: (feedbackId, token) => dispatch(getChatDetail({ feedbackId, token })),
    updateRateChatOptions: (detail) => dispatch(updateRateChatOptions(detail)),
    setTimelineItems: (items) => dispatch(setTimelineItems(items)),
    archiveModal: () => dispatch(archiveModal()),
    sendArchiveFeedback: () => dispatch(sendArchiveFeedback()),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: 'chat', reducer });
const withSaga = injectSaga({ key: 'chat', saga });

export default compose(withReducer, withSaga, withConnect)(Chat);
