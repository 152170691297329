/**
 *
 * WidgetFrame
 *
 */

import ErrorBoundary from '@pisano/feedback-core/app/components/ErrorBoundary';
import PisanoPoweredLogo from '@pisano/feedback-core/app/components/PisanoPoweredLogo';
import Flow from '@pisano/feedback-core/app/containers/Flow/Loadable';
import LanguageToggle, { LANGUAGE_SELECTOR } from '@pisano/feedback-core/app/containers/LanguageToggle';
import Translate from '@pisano/feedback-core/app/containers/Translate/Loadable';
import CaretRight from '@pisano/feedback-core/app/images/caret-right.svg';
import scrollToBottom from '@pisano/feedback-core/app/utils/scrollToBottom';
import Frame from '@pisano/react-frame-component';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Chat from '../Chat/Loadable';
import { openPreviousFeedback } from '../Widget/actions';
import { MOBILE_REGEX } from '../Widget/constants';
import WidgetFrameHeader from '../WidgetFrameHeader';

// Exactly fit 5 sentiment options and an NPS question.
const FRAME_BASE_PIXEL_WIDTH = 355;
const FRAME_BASE_RATE_WIDTH = 30;
const FRAME_CONTENT_MIN_HEIGHT = 250;

export class WidgetFrame extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    const { width, widthType } = this.props;
    this.state = {
      width: width || (widthType === 'rate' ? FRAME_BASE_RATE_WIDTH : FRAME_BASE_PIXEL_WIDTH),
      widthType: widthType === 'rate' ? '%' : 'px',
      height: 450,
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      const { isWidgetOpen } = this.props;
      if (isWidgetOpen && this.widgetContent) {
        const { width, height } = this.getContentSize();
        if (Math.abs(width - this.state.width) > 1 || Math.abs(height - this.state.height) > 1) {
          // Extra 60px from header, 50px from footer for height
          this.setState({ width, height: height + 60 + 50 });
        }
      }
    }, 50);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getContentSize() {
    const height = Math.max(
      FRAME_CONTENT_MIN_HEIGHT,
      Array.from(this.widgetContent.children).reduce((xs, x) => xs + x.scrollHeight, 0),
    );
    const width = this.state.width + this.widgetContent.offsetWidth - this.widgetContent.clientWidth;
    return { height, width };
  }

  getFrameStyles() {
    const { size, margin, isModalWidget, radius } = this.props;
    const maxHeight = Math.min(600, window.innerHeight - (isModalWidget ? 40 : size + 10 + margin * 2));

    const style = {
      maxHeight,
      width: this.state.width,
      height: this.state.height,
      borderRadius: radius,
    };

    if (isModalWidget) {
      return style;
    }

    return {
      ...style,
      right: margin,
      bottom: size + margin * 2,
    };
  }

  renderFlowOrChat() {
    const { nodeId, displayedBefore, feedbackId, customer, token } = this.props;
    // Don't render if the widget is not opened or the flow is not loaded or customer info doesn't exist yet.
    if (!nodeId || !displayedBefore || !customer) {
      return null;
    }

    if (feedbackId && token) {
      const chatQueryParams = { feedback_id: feedbackId, token };
      const onChatUpdate = () => scrollToBottom(this.widgetContent);
      return <Chat queryParams={chatQueryParams} onUpdate={onChatUpdate} insideWidget />;
    }
    const flowQueryParams = {
      node_id: nodeId,
      creator_id: customer.id,
    };
    return (
      <Flow
        queryParams={flowQueryParams}
        insideWidget
        defaultPlaceholder="webPlaceholder"
        animationsEnaled={false}
        timeoutEnabled={false}
      />
    );
  }

  renderFooter() {
    const { feedbackId, isSelectingPreviousFeedback, displayBranding } = this.props;
    const shouldRenderLanguageToggle = !feedbackId && !isSelectingPreviousFeedback;

    return (
      <div
        className={`widget-content-container__footer${
          displayBranding ? ' widget-content-container__footer--with-top-border' : ''
        }`}
      >
        {displayBranding && (
          <div>
            <PisanoPoweredLogo />
          </div>
        )}
        {shouldRenderLanguageToggle && (
          <LanguageToggle
            className="widget-content-container__footer__language-toggle"
            languageSelector={LANGUAGE_SELECTOR.DROPDOWN_UP}
          />
        )}
      </div>
    );
  }

  renderPreviousFeedbacksScreen() {
    // TODO: Create feedback button
    const { previousFeedbacks, openPreviousFeedback } = this.props;
    return (
      <div className="previous-feedback-container">
        {previousFeedbacks.map((feedback) => (
          <div
            className="previous-feedback"
            onClick={() => openPreviousFeedback(feedback.id)}
            role="button"
            tabIndex={0}
            key={feedback.id}
          >
            <div
              className="previous-feedback__avatar"
              style={{
                backgroundImage: 'url("https://www.pisano.co/assets/img/pisano-logo-big.png")',
              }}
            />
            <div className="previous-feedback__information">
              <div className="previous-feedback__information__first-row">
                <span>Fehmi Yemeni</span>
                <span>
                  {Math.random() < 0.5 ? (
                    '3 gun once'
                  ) : (
                    <div className="previous-feedback__information__first-row__notification-count">
                      {Math.floor(Math.random() * 1000)}
                    </div>
                  )}
                </span>
              </div>

              <div className="previous-feedback__information__second-row">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptatibus, dolorum maxime? Molestias
                similique velit tenetur fuga corrupti beatae esse quo. Sequi vero eos fugit magnam doloribus sint rerum
                temporibus cum.
              </div>
            </div>
          </div>
        ))}

        <div className="new-feedback-button">
          <Translate message="newFeedback" />
          <CaretRight style={{ marginLeft: '20px' }} />
        </div>
      </div>
    );
  }

  render() {
    const { isWidgetOpen, isSelectingPreviousFeedback, isFullPageOnMobile, initialFrameContent } = this.props;

    const mobileFullPageClass = {
      'mobile-full-page': isFullPageOnMobile && MOBILE_REGEX.test(navigator.userAgent),
    };

    const contentFrameClass = classNames(mobileFullPageClass, {
      'psn-widget-invisible': !isWidgetOpen,
    });
    const widgetContentContainerClass = classNames('widget-content-container', mobileFullPageClass, {
      'psn-widget-invisible': !isWidgetOpen,
    });

    const errorWrapper = (p) => (
      <div className="center-absolute" style={{ width: '75%' }}>
        {p.children}
      </div>
    );

    return (
      <Frame
        initialContent={initialFrameContent}
        frameRef={(node) => {
          if (node) {
            node.style.setProperty('width', `${this.state.width}${this.state.widthType}`, 'important');
          }
        }}
        allowFullScreen
        id="psn-widget-content-frame"
        className={contentFrameClass}
        title="Pisano Widget Frame"
        style={this.getFrameStyles()}
      >
        <div className={widgetContentContainerClass}>
          <ErrorBoundary wrapper={errorWrapper}>
            <WidgetFrameHeader />
            <div
              className="widget-content"
              ref={(el) => {
                this.widgetContent = el;
              }}
            >
              {isSelectingPreviousFeedback ? this.renderPreviousFeedbacksScreen() : this.renderFlowOrChat()}
            </div>
          </ErrorBoundary>
          {this.renderFooter()}
        </div>
      </Frame>
    );
  }
}

WidgetFrame.propTypes = {
  nodeId: PropTypes.string,
  size: PropTypes.number.isRequired,
  width: PropTypes.number,
  widthType: PropTypes.string,
  margin: PropTypes.number.isRequired,
  isWidgetOpen: PropTypes.bool.isRequired,
  displayedBefore: PropTypes.bool.isRequired,
  isModalWidget: PropTypes.bool.isRequired,
  isFullPageOnMobile: PropTypes.bool.isRequired,
  isSelectingPreviousFeedback: PropTypes.bool,
  openPreviousFeedback: PropTypes.func.isRequired,
  previousFeedbacks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      createdAt: PropTypes.string,
    }),
  ),
  feedbackId: PropTypes.string,
  token: PropTypes.string,
  customer: PropTypes.object,
  initialFrameContent: PropTypes.string,
  radius: PropTypes.number,
  displayBranding: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    nodeId: state.widget.nodeId,
    size: state.widget.size,
    width: state.widget.width,
    widthType: state.widget.widthType,
    margin: state.widget.margin,
    isWidgetOpen: state.widget.isOpen,
    displayedBefore: state.widget.displayedBefore,
    isModalWidget: state.widget.isModal,
    isFullPageOnMobile: state.widget.isFullPageOnMobile,
    isSelectingPreviousFeedback: state.widget.isSelectingPreviousFeedback,
    previousFeedbacks: state.widget.previousFeedbacks,
    feedbackId: state.widget.feedbackId,
    token: state.widget.token,
    customer: state.widget.customer,
    radius: state.widget.radius,
    displayBranding: state.widget.displayBranding,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    openPreviousFeedback: (feedbackId) => dispatch(openPreviousFeedback(feedbackId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WidgetFrame);
