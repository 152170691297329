/**
 *
 * WidgetFrameHeader
 *
 */

import { previousState } from '@pisano/feedback-core/app/containers/Flow/actions';
import { CHAT, THANK } from '@pisano/feedback-core/app/containers/FlowState/stateTypes';
import CaretRight from '@pisano/feedback-core/app/images/caret-right.svg';
import Close from '@pisano/feedback-core/app/images/close.svg';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { archiveModal } from '../Chat/actions';
import { closeWidget, hidePreviousFeedbacks, showPreviousFeedbacks } from '../Widget/actions';
import Hamburger from './images/hamburger.svg';

const renderPreviousFeedbacksToggle = (onClick) => (
  <div className="widget-content-container__header__button left" onClick={onClick} role="button" tabIndex={0}>
    <Hamburger />
  </div>
);

const renderCloseButton = (onClick) => (
  <div
    className="widget-content-container__header__button widget-close-button"
    onClick={onClick}
    role="button"
    tabIndex={0}
  >
    <CaretRight style={{ transform: 'rotate(90deg)' }} />
  </div>
);

const renderArchiveButton = (onClick) => (
  <div
    className="widget-content-container__header__button widget-archive-button"
    onClick={onClick}
    role="button"
    tabIndex={0}
  >
    <Close />
  </div>
);

const renderBackButton = (onClick) => (
  <div className="widget-content-container__header__button left" onClick={onClick} role="button" tabIndex={0}>
    <CaretRight style={{ transform: 'rotate(180deg)' }} />
  </div>
);

export function WidgetFrameHeader({
  backgroundColor,
  nodeLogoUrl,
  closeWidget,
  fontColor,
  isSelectingPreviousFeedback,
  showPreviousFeedbacks,
  hidePreviousFeedbacks,
  shouldRenderBackButton,
  shouldRenderPreviousFeedbacksToggle,
  previousState,
  archiveModal,
  chat,
}) {
  const togglePreviousFeedbacks = isSelectingPreviousFeedback ? hidePreviousFeedbacks : showPreviousFeedbacks;
  // Give priority to back button over previous feedbacks toggle.
  const backbButtonOnClick = isSelectingPreviousFeedback ? togglePreviousFeedbacks : previousState;
  const leftButton =
    (shouldRenderBackButton && renderBackButton(backbButtonOnClick)) ||
    (shouldRenderPreviousFeedbacksToggle && renderPreviousFeedbacksToggle(togglePreviousFeedbacks));
  return (
    <div className="widget-content-container__header" style={{ backgroundColor, color: fontColor }}>
      {leftButton}
      {nodeLogoUrl && <img src={nodeLogoUrl} alt="" className="logo" />}
      <div className="widget-content-container__header__button-group">
        {chat && chat.status === 'open' && renderArchiveButton(archiveModal)}
        {renderCloseButton(closeWidget)}
      </div>
    </div>
  );
}

WidgetFrameHeader.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  fontColor: PropTypes.string.isRequired,
  nodeLogoUrl: PropTypes.string,
  chat: PropTypes.object,
  closeWidget: PropTypes.func.isRequired,
  isSelectingPreviousFeedback: PropTypes.bool.isRequired,
  showPreviousFeedbacks: PropTypes.func.isRequired,
  hidePreviousFeedbacks: PropTypes.func.isRequired,
  previousState: PropTypes.func.isRequired,
  shouldRenderBackButton: PropTypes.bool,
  shouldRenderPreviousFeedbacksToggle: PropTypes.bool,
  archiveModal: PropTypes.func.isRequired,
};

WidgetFrameHeader.defaultProps = {
  shouldRenderBackButton: false,
  shouldRenderPreviousFeedbacksToggle: false,
};

function mapStateToProps(state) {
  return {
    backgroundColor: state.widget.primaryColor,
    fontColor: state.widget.fontColor,
    nodeLogoUrl: state.widget.nodeLogoUrl,
    isSelectingPreviousFeedback: state.widget.isSelectingPreviousFeedback,
    chat: state.chat,
    shouldRenderBackButton: !!(
      (state.flow &&
        state.flow.currentState.previousState &&
        state.flow.currentState.style !== THANK &&
        state.flow.currentState.style !== CHAT) ||
      state.widget.isSelectingPreviousFeedback
    ),
    shouldRenderPreviousFeedbacksToggle: false, // state.widget.previousFeedbacks.length > 0,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    closeWidget: () => dispatch(closeWidget()),
    showPreviousFeedbacks: () => dispatch(showPreviousFeedbacks()),
    hidePreviousFeedbacks: () => dispatch(hidePreviousFeedbacks()),
    previousState: () => dispatch(previousState()),
    archiveModal: () => dispatch(archiveModal()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WidgetFrameHeader);
