export default [
  // GTM tracker
  (action) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: action.type,
      payload: action.payload,
    });
  },
  // New trackers can be added here, e.g. (action, state) => console.log(action, state)
];
