import { LABEL } from '../../components/FlowStateElement/elementTypes';
import { THANK } from './stateTypes';

const DEFAULT_THANK_STATE = {
  element: {
    id: 'default-thank-label',
    body: {
      EN: '# Thank You',
      TR: '# Teşekkür Ederiz',
    },
    type: LABEL,
    order: 0,
    isVisible: true,
  },
  state: {
    id: 'default-thank-state',
    name: 'Default Thank State',
    isInitialState: false,
    style: THANK,
    elements: ['default-thank-label'],
  },
};

export default DEFAULT_THANK_STATE;
