import { customContentInjector } from '@pisano/feedback-core/app/utils/customContentInjector';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from 'react';
import { Route } from 'react-router-dom';

function ProtectedRoute({ component: Component, loadCss, componentProps = {}, requiredQueryParams, ...rest }) {
  const query = Array.isArray(requiredQueryParams) ? requiredQueryParams : [requiredQueryParams];
  const params = queryString.parse(window.location.search);
  const valid = query.every((q) => !!params[q]);

  if (requiredQueryParams && !valid) {
    return null;
  }

  customContentInjector({ cssUrl: loadCss, name: 'protectedRoute' });

  return <Route {...rest} render={(props) => <Component {...props} {...componentProps} />} />;
}

ProtectedRoute.propTypes = {
  component: PropTypes.func,
  componentProps: PropTypes.object,
  loadCss: PropTypes.string,
  requiredQueryParams: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};

export default ProtectedRoute;
