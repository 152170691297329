/*
 *
 * Chat reducer
 *
 */

import {
  CHAT_DETAIL_LOADED,
  CHAT_DETAIL_LOADING_ERROR,
  SET_TIMELINE_ITEMS,
  ADD_TIMELINE_ITEM,
  SET_TIMELINE_ITEM,
  CHAT_RATED,
  CHAT_RATING_ERROR,
  UPDATE_RATE_CHAT_OPTIONS,
  SET_LOADING,
  ARCHIVE_MODAL,
} from './constants';

export const initialState = {
  error: null,
  node: {
    isOperating: true,
  },
  creator: {},
  responses: [],
  creator_language_code: 'en',
  created_at: '-1',
  isRated: false,
  chat_ratings: [],
  timeline_items: [],
  loading: true,
  isPisanoAgent: /PisanoSDK/i.test(navigator.userAgent),
  isArchiveModal: false,
};

function chatReducer(state = initialState, action) {
  switch (action.type) {
    case CHAT_DETAIL_LOADED:
      return { ...state, ...action.payload, loading: false };
    case CHAT_DETAIL_LOADING_ERROR:
      return { ...state, error: action.payload, loading: false };
    case SET_TIMELINE_ITEMS:
      return { ...state, timeline_items: action.payload };
    case ADD_TIMELINE_ITEM:
      return {
        ...state,
        timeline_items: state.timeline_items.concat(action.payload),
      };
    case SET_TIMELINE_ITEM:
      const timelineItem = {
        ...state.timeline_items.find((item) => item.id === action.payload.id),
        ...action.payload.item,
      };
      return {
        ...state,
        timeline_items: state.timeline_items.filter((item) => item.id !== action.payload.id).concat(timelineItem),
      };
    case CHAT_RATED:
      return {
        ...state,
        chat_ratings: state.chat_ratings.concat(action.payload),
        isRated: true,
        selectedRating: action.payload.rating,
      };
    case CHAT_RATING_ERROR:
      return { ...state, chatRatingError: action.payload };
    case UPDATE_RATE_CHAT_OPTIONS:
      return {
        ...state,
        status: action.payload.status,
        chat_rating_enabled: action.payload.chatRatingEnabled,
        isRated: action.payload.isRated,
        selectedRating: action.payload.selectedRating,
      };
    case SET_LOADING:
      return { ...state, loading: action.payload };
    case ARCHIVE_MODAL:
      return { ...state, isArchiveModal: !state.isArchiveModal };
    default:
      return state;
  }
}

export default chatReducer;
