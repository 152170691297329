/*
 *
 * ChatInput actions
 *
 */

import { SEND_MESSAGE, SELECT_FILE, REMOVE_FILE, UPDATE_PROGRESS } from './constants';

export function sendMessage(payload) {
  return {
    type: SEND_MESSAGE,
    payload,
  };
}

export function selectFile(payload) {
  return {
    type: SELECT_FILE,
    payload,
  };
}

export function removeFile(payload) {
  return {
    type: REMOVE_FILE,
    payload,
  };
}

export function updateProgress(payload) {
  return {
    type: UPDATE_PROGRESS,
    payload,
  };
}
