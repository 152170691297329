/**
 *
 * App.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages.
 *
 */

import PropTypes from 'prop-types';
import React from 'react';
import appCss from '../../app.scss';
import { customContentInjector } from '../../utils/customContentInjector';

export default function App({ children }) {
  customContentInjector({ cssUrl: appCss, name: 'app' });

  return <div style={{ height: '100%' }}>{children}</div>;
}

App.propTypes = {
  children: PropTypes.node,
};
