export const AGE = 'age';
export const AGREEMENT = 'agreement';
export const CHECKLIST = 'checklist';
export const CUSTOM_SCORE = 'custom_score';
export const CUSTOMER_SCHEMA = 'customer_schema';
export const DATEPICKER = 'datepicker';
export const DROPDOWN = 'dropdown';
export const DYNAMIC_MULTIPLE_CHOICE = 'dynamic_multiple_choice';
export const DYNAMIC_MULTIPLE_VALUE = 'dynamic_multiple_value';
export const DYNAMIC_REQUEST = 'dynamic_request';
export const EMAIL = 'email';
export const EXTERNAL_ID = 'external_id';
export const FILE = 'file';
export const GENDER = 'gender';
export const HIDDEN_VALIDATION = 'hidden_validation';
export const MATRIX = 'matrix';
export const NAMING = 'naming';
export const NUMERIC = 'numeric';
export const PHONE_NUMBER = 'phone_number';
export const PHONE_VERIFICATION = 'phone_verification';
export const PLAIN = 'plain';
export const RADIO = 'radio';
export const RANDOM = 'random';
export const RANKING = 'ranking';
export const SCORE = 'score';
export const SCREENSHOT = 'screenshot';
export const SLIDER = 'slider';
export const STAR = 'star';
export const TEXT = 'text';
export const TEXTAREA = 'textarea';
