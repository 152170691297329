/*
 *
 * ChatRating constants
 *
 */

// Action Constants
export const RATE_CHAT = 'app/ChatRating/RATE_CHAT';

export const AVAILABLE_CHAT_RATINGS = [
  {
    weight: 0,
    body: ':slight_frown:',
  },
  {
    weight: 50,
    body: ':neutral_face:',
  },
  {
    weight: 100,
    body: ':slight_smile:',
  },
];
