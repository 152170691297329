import last from 'lodash/last';
import createSocket from '@pisano/feedback-core/app/socket';

let socket = null;

const getChatRateFields = (data) => {
  const socketFields = {
    chatRatingEnabled: data.chat_rating_enabled,
    status: data.status,
  };
  const lastChatRating = data.chat_ratings[0] || {};
  const lastComment = last(data.timeline_items.filter((item) => item.actor === 'customer')) || {};
  if (lastComment.created_at <= lastChatRating.created_at) {
    socketFields.isRated = true;
    socketFields.selectedRating = lastChatRating.rating;
  } else {
    socketFields.isRated = false;
    socketFields.selectedRating = null;
  }
  return socketFields;
};

const subscribeRooms = (feedbackId) => {
  socket.emit('subscribe', { roomId: 'chat', feedbackId });
};

const unsubscribeRooms = (feedbackId) => {
  socket.emit('unsubscribe', { roomId: 'chat', feedbackId });
};

let connectAgain;

export const setupSocket = (setTimelineItems, updateRateChatOptions, feedbackId) => {
  socket = createSocket();
  subscribeRooms(feedbackId);

  socket.on('reply_created', (data) => {
    updateRateChatOptions(getChatRateFields(data));
    setTimelineItems(data.timeline_items);
  });

  connectAgain = () => {
    subscribeRooms(feedbackId);
  };
  socket.on('connect', connectAgain);
};

export const destroySocket = (feedbackId) => {
  unsubscribeRooms(feedbackId);
  socket.removeAllListeners('reply_created');
  socket.removeListener('connect', connectAgain);
};
