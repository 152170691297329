/*
 *
 * Widget actions
 *
 */

import {
  ADD_PREVIOUS_FEEDBACK,
  CLOSE_WIDGET,
  HIDE_PREVIOUS_FEEDBACKS,
  HIDE_WIDGET,
  LOAD_CUSTOMER,
  LOAD_CUSTOMER_ERROR,
  LOAD_CUSTOMER_SUCCESS,
  LOAD_WIDGET,
  LOAD_WIDGET_CSS,
  LOAD_WIDGET_CSS_SUCCESS,
  LOAD_WIDGET_ERROR,
  LOAD_WIDGET_SUCCESS,
  OPEN_CHAT,
  OPEN_PREVIOUS_FEEDBACK,
  OPEN_WIDGET,
  OPEN_WIDGET_ONCE,
  SET_WIDGET_INITIAL_DISPLAY_STATUS,
  SHOW_PREVIOUS_FEEDBACKS,
  SHOW_WIDGET,
} from './constants';

export function loadWidget(payload) {
  return {
    type: LOAD_WIDGET,
    payload,
  };
}

export function widgetLoaded(payload) {
  return {
    type: LOAD_WIDGET_SUCCESS,
    payload,
  };
}

export function loadWidgetCss(payload) {
  return {
    type: LOAD_WIDGET_CSS,
    payload,
  };
}

export function widgetCssLoaded({ frameCss, innerCss }) {
  return {
    type: LOAD_WIDGET_CSS_SUCCESS,
    payload: { frameCss, innerCss },
  };
}

export function widgetLoadingError(error) {
  return {
    type: LOAD_WIDGET_ERROR,
    payload: error,
  };
}

export function openWidget() {
  return {
    type: OPEN_WIDGET,
  };
}

export function openWidgetOnce() {
  return {
    type: OPEN_WIDGET_ONCE,
  };
}

export function closeWidget() {
  return {
    type: CLOSE_WIDGET,
  };
}

export function showWidget() {
  return {
    type: SHOW_WIDGET,
  };
}

export function hideWidget() {
  return {
    type: HIDE_WIDGET,
  };
}

export function openChat({ feedbackId, token }) {
  return {
    type: OPEN_CHAT,
    payload: { feedbackId, token },
  };
}

export function addPreviousFeedback(feedback) {
  return {
    type: ADD_PREVIOUS_FEEDBACK,
    payload: { feedback },
  };
}

export function showPreviousFeedbacks() {
  return {
    type: SHOW_PREVIOUS_FEEDBACKS,
  };
}

export function hidePreviousFeedbacks() {
  return {
    type: HIDE_PREVIOUS_FEEDBACKS,
  };
}

export function openPreviousFeedback(feedbackId) {
  return {
    type: OPEN_PREVIOUS_FEEDBACK,
    payload: { feedbackId },
  };
}

export function loadCustomer(payload) {
  return {
    type: LOAD_CUSTOMER,
    payload,
  };
}

export function customerLoaded(payload) {
  return {
    type: LOAD_CUSTOMER_SUCCESS,
    payload,
  };
}

export function customerLoadingError(error) {
  return {
    type: LOAD_CUSTOMER_ERROR,
    payload: error,
  };
}

export function setWidgetInitialDisplayStatus() {
  return {
    type: SET_WIDGET_INITIAL_DISPLAY_STATUS,
  };
}
