import Loading from '@pisano/feedback-core/app/components/Loading';
import Translate from '@pisano/feedback-core/app/containers/Translate/Loadable';
import linkifyStr from 'linkify-string';
import PropTypes from 'prop-types';
import React from 'react';

const formatDateTime = (timestamp, locale) => {
  const date = new Date(timestamp);
  const now = new Date();

  const timeFormat = { hour: '2-digit', minute: '2-digit' };
  const monthDayFormat = { day: 'numeric', month: 'long' };
  const yearFormat = { year: 'numeric' };

  if (date.toDateString() === now.toDateString()) {
    // If the date is today, just show time.
    return date.toLocaleTimeString(locale, timeFormat);
  }

  if (date.getFullYear() === now.getFullYear()) {
    // If the date is this year, show month, day and time.
    return date.toLocaleString(locale, { ...timeFormat, ...monthDayFormat });
  }

  // Otherwise show the whole date.
  return date.toLocaleString(locale, {
    ...timeFormat,
    ...monthDayFormat,
    ...yearFormat,
  });
};

const linkifyOptions = {
  className: 'link',
  defaultProtocol: 'https',
  ignoreTags: ['script', 'style'],
  target: '_blank',
  truncate: 25,
  rel: 'nofollow noreferrer noopener',
};

const formatBody = (body) => {
  if (!body || !body.trim) return body;

  let i = 0;
  return body
    .split('\n')
    .map((line) => linkifyStr(line, linkifyOptions))
    .map((line) => <Translate key={i++} message={line} emojify />);
};

// NOTE: Currently this is not working, because we cannot get the parsed emoji from `message` prop of message body.
// We can't do it with pure CSS as well because we cannot select text nodes in CSS.
const getEmojiClass = (messageBodyLines) => {
  if (!messageBodyLines || messageBodyLines.length !== 1) {
    return '';
  }
  const body = messageBodyLines[0].props.message;
  const EMOJIONE_RE = /<img[^>]*class="emojione"[^>]*>/gi;

  const emojiMatches = body.match(EMOJIONE_RE);
  const emojiCount = emojiMatches ? emojiMatches.length : 0;

  // Check if the body has only emojis.
  if (body.replace(EMOJIONE_RE, '').length !== 0) {
    return '';
  }

  if (emojiCount === 1) {
    return 'x-large-emoji';
  }
  if (emojiCount === 2) {
    return 'large-emoji';
  }
  if (emojiCount === 3) {
    return 'medium-emoji';
  }
  return '';
};

function ChatMessage({ body, attachment, createdAt, hasError, showTicks, isSending, status, queueBody }) {
  const messageBody = formatBody(body);

  if (status === 'queued') {
    return (
      <div className="comment-action">
        <div className="comment-action-body">
          <div className="action-body">
            <Translate message={queueBody} />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="comment-action">
      <div className="comment-action-body">
        {attachment && (
          <a target="_blank" href={attachment} className="comment-attachment">
            <img src={attachment} alt="" />
          </a>
        )}
        <div className="action-body">
          <div className={`action-body-text ${getEmojiClass(messageBody)}`}>{messageBody}</div>
          <div className="action-time">{formatDateTime(createdAt)}</div>
          {(showTicks || isSending) && (
            <div className="action-ticks">
              {!isSending && (hasError ? <span className="text-error">✗</span> : <span>✓</span>)}
              {isSending && <Loading size={8} />}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

ChatMessage.propTypes = {
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  attachment: PropTypes.string,
  createdAt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  showTicks: PropTypes.bool,
  isSending: PropTypes.bool,
  hasError: PropTypes.bool,
  status: PropTypes.string,
  queueBody: PropTypes.object,
};

ChatMessage.defaultProps = {
  showTicks: false,
  isSending: false,
  hasError: false,
};

export default ChatMessage;
