import { CHAT, THANK } from '../containers/FlowState/stateTypes';

export function handleStatePageClassClear() {
  handleStatePageClass(null);
}

export function handleStatePageClass(stateType) {
  const toggleClass = (el) => {
    if (!el) {
      return;
    }

    const classes = {
      [CHAT]: 'app-chat-page',
      [THANK]: 'app-thank-you-page',
    };

    Object.entries(classes).forEach(([key, value]) => {
      if (stateType === key) {
        el.classList.add(value);
      } else {
        el.classList.remove(value);
      }
    });
  };

  const widgetFrame = document.getElementById('psn-widget-content-frame');

  if (widgetFrame) {
    const el = widgetFrame.contentWindow.document.getElementById('pisano-frame-root');
    toggleClass(el);
  } else {
    const el = document.getElementById('app');
    toggleClass(el);
  }
}
