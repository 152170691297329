/**
 *
 * Widget
 *
 */

import { customContentInjector } from '@pisano/feedback-core/app/utils/customContentInjector';
import injectReducer from '@pisano/feedback-core/app/utils/injectReducer';
import injectSaga from '@pisano/feedback-core/app/utils/injectSaga';
import { SentryReport } from '@pisano/feedback-core/app/utils/sentry';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import WidgetButton from '../WidgetButton';
import WidgetFrame from '../WidgetFrame';
import { closeWidget, hideWidget, loadWidget, loadWidgetCss, openWidget, showWidget } from './actions';
import { MOBILE_REGEX, WIDGET_PREFIX } from './constants';
import { getWidgetStore } from './helpers/widgetStores';
import reducer from './reducer';
import saga from './saga';

export class Widget extends React.PureComponent {
  componentDidMount() {
    const { nodeId, frameCss, isModal, location, loadWidget, customer, lang, customAttributes } = this.props;
    if (nodeId) {
      // If we have the nodeId, this means it's being loaded with PisanoWidget.init().
      // We just have to load the CSS files.
      if (frameCss) return; // everything is already loaded
      loadWidgetCss({ isModal });
    } else {
      const code = this.props.code || queryString.parse(location.search).code;
      if (code.substring(0, 4) !== WIDGET_PREFIX || code.length !== 11) return;
      loadWidget({ code, lang, customer, customAttributes });
    }
  }

  componentDidUpdate() {
    const { enabledUrls, isEnabledOnMobile, nodeId } = this.props;
    if (!isEnabledOnMobile && MOBILE_REGEX.test(navigator.userAgent)) {
      this.props.hideWidget();
      return;
    }

    const { isWidgetDisplayedBefore } = getWidgetStore(nodeId);
    const isDisplayedBefore = isWidgetDisplayedBefore || false;
    let isDisplayOnceActive = false;

    if (this.props.displayOnce && isDisplayedBefore) {
      isDisplayOnceActive = true;
    }

    // A defensive check where we might have troubles with localStorage permission.
    if (this.props.displayOnExit && !this.mouseLeaveBinded && !isDisplayOnceActive) {
      this.handleMouseOut = (event) => {
        try {
          event = event || window.event;

          if (this.props.displayedBefore) return;

          const excludedElements = ['input', 'select', 'fieldset'];
          const targetName = event.target.tagName.toLowerCase();
          if (excludedElements.includes(targetName)) return;

          // If the current mouse X position is within 50px of the right edge
          // of the viewport, return.
          const vpWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
          if (event.clientX >= vpWidth - 50) return;

          // If the current mouse Y position is not within 50px of the top
          // edge of the viewport, return.
          if (event.clientY >= 50) return;

          // Reliable, works on mouse exiting window and
          // user switching active program
          const from = event.relatedTarget || event.toElement;
          if (!from) {
            this.context.store.dispatch(openWidget());
            document.body.removeEventListener('mouseout', this.handleMouseOut);
          }
        } catch (e) {
          SentryReport({ error: e, info: null, state: this.context.store.getState() });
        }
      };

      document.body.addEventListener('mouseout', this.handleMouseOut);
      this.mouseLeaveBinded = true;
    }

    const shouldAutoOpenWidget =
      this.props.displayInterval > 0 &&
      this.props.isLoaded &&
      !this.props.isOpen &&
      !this.displayIntervalTimeout &&
      !isDisplayOnceActive &&
      !this.props.shouldHideWidget;

    if (shouldAutoOpenWidget) {
      this.displayIntervalTimeout = setTimeout(() => {
        this.context.store.dispatch(openWidget());
      }, this.props.displayInterval * 1000);
    }

    if (enabledUrls && enabledUrls.length && !this.enabledUrlsInterval) {
      let lastCheckedURL;
      this.enabledUrlsInterval = setInterval(() => {
        if (lastCheckedURL === window.location.href) return;
        lastCheckedURL = window.location.href;
        if (this.isEnabledOnThisPage()) {
          this.props.showWidget();
        } else if (this.props.isVisible) {
          this.props.hideWidget();
        }
      });
    }
  }

  componentWillUnmount() {
    if (this.enabledUrlsInterval) {
      clearInterval(this.enabledUrlsInterval);
    }
    clearAllBodyScrollLocks();
  }

  isEnabledOnThisPage() {
    const { enabledUrls } = this.props;
    return enabledUrls
      .map((regex) => {
        try {
          return new RegExp(regex, 'i').test(window.location.href);
        } catch (e) {
          return false;
        }
      })
      .reduce((a, b) => a || b);
  }

  render() {
    const { error, isLoaded, isModal, isOpen, isVisible, innerCss, closeWidget, frameCss } = this.props;
    if (error) {
      return null;
      // throw createError(ReportedError, 'widgetLoadingError', error);
    }

    if (!isLoaded) return null;

    // HTML template for iframes (button and content container)
    const initialFrameContent = `
      <!DOCTYPE html>
      <html translate="no">
        <head>
          <base target="_parent" />
          <style type="text/css">${innerCss}</style>
        </head>
        <body>
          <div id="pisano-frame-root"></div>
        </body>
      </html>
    `;

    const modalOverlayClass = classNames({ 'psn-widget-visible': isOpen });
    const widgetButtonClass = classNames({ 'psn-widget-invisible': !isVisible });

    customContentInjector({ styles: frameCss, name: 'widget' });

    return (
      <div id="psn-widget-root">
        <WidgetFrame initialFrameContent={initialFrameContent} />
        <WidgetButton initialFrameContent={initialFrameContent} className={widgetButtonClass} />
        {/* eslint-disable jsx-a11y/no-static-element-interactions */}
        {isModal && <div id="psn-modal-overlay" className={modalOverlayClass} onClick={closeWidget} />}
        {/* eslint-enable jsx-a11y/no-static-element-interactions */}
      </div>
    );
  }
}

Widget.propTypes = {
  error: PropTypes.object,
  nodeId: PropTypes.string,
  code: PropTypes.string,
  customer: PropTypes.object,
  lang: PropTypes.string,
  isModal: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  isLoaded: PropTypes.bool,
  innerCss: PropTypes.string,
  frameCss: PropTypes.string,
  enabledUrls: PropTypes.arrayOf(PropTypes.string),
  isEnabledOnMobile: PropTypes.bool,
  location: PropTypes.object,
  loadWidget: PropTypes.func.isRequired,
  showWidget: PropTypes.func.isRequired,
  hideWidget: PropTypes.func.isRequired,
  closeWidget: PropTypes.func.isRequired,
  displayInterval: PropTypes.number,
  displayOnce: PropTypes.bool,
  displayOnExit: PropTypes.bool,
  customAttributes: PropTypes.object,
  displayedBefore: PropTypes.bool,
  shouldHideWidget: PropTypes.bool,
};

Widget.defaultProps = {
  location: {
    search: '',
  },
};

Widget.contextTypes = {
  store: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    nodeId: state.widget.nodeId,
    error: state.widget.error,
    isModal: state.widget.isModal,
    isOpen: state.widget.isOpen,
    isVisible: state.widget.isVisible,
    isLoaded: state.widget.isLoaded,
    innerCss: state.widget.innerCss,
    frameCss: state.widget.frameCss,
    enabledUrls: state.widget.enabledUrls,
    isEnabledOnMobile: state.widget.isEnabledOnMobile,
    displayInterval: state.widget.displayInterval,
    displayOnce: state.widget.displayOnce,
    displayOnExit: state.widget.displayOnExit,
    displayedBefore: state.widget.displayedBefore,
    shouldHideWidget: state.widget.shouldHideWidget,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadWidget: (payload) => dispatch(loadWidget(payload)),
    showWidget: () => dispatch(showWidget()),
    hideWidget: () => dispatch(hideWidget()),
    closeWidget: () => dispatch(closeWidget()),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: 'widget', reducer });
const withSaga = injectSaga({ key: 'widget', saga });

export default compose(withReducer, withSaga, withConnect)(Widget);
