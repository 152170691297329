/*
 *
 * Chat constants
 *
 */

export const GET_CHAT_DETAIL = 'app/Chat/GET_CHAT_DETAIL';
export const CHAT_DETAIL_LOADED = 'app/Chat/CHAT_DETAIL_LOADED';
export const CHAT_DETAIL_LOADING_ERROR = 'app/Chat/CHAT_DETAIL_LOADING_ERROR';
export const SET_TIMELINE_ITEMS = 'app/Chat/SET_TIMELINE_ITEMS';
export const ADD_TIMELINE_ITEM = 'app/Chat/ADD_TIMELINE_ITEM';
export const SET_TIMELINE_ITEM = 'app/Chat/SET_TIMELINE_ITEM';
export const CHAT_RATED = 'app/ChatRating/CHAT_RATED';
export const CHAT_RATING_ERROR = 'app/ChatRating/CHAT_RATING_ERROR';
export const UPDATE_RATE_CHAT_OPTIONS = 'app/Chat/UPDATE_RATE_CHAT_OPTIONS';
export const SET_LOADING = 'app/Chat/SET_LOADING';
export const ARCHIVE_MODAL = 'app/Chat/ARCHIVE_MODAL';
export const SEND_ARCHIVE_FEEDBACK = 'app/Chat/SEND_ARCHIVE_FEEDBACK';
