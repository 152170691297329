import { PISANO_SESSION } from '../containers/Flow/constants';
import localStorage from './localStorage';

function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
  );
}

export const generatePisanoSession = () => {
  if (localStorage.getItem(PISANO_SESSION)) {
    return;
  }

  const timestamp = new Date().getTime();

  const PisanoSession = { id: uuidv4(), timestamp };

  localStorage.setItem(PISANO_SESSION, JSON.stringify(PisanoSession));
};

const getPisanoSession = () => localStorage.getItem(PISANO_SESSION);

window.PisanoSession = getPisanoSession;
