export const defaultCreditCardMaskPattern = 'XXXX-XXXX-XXXX-XXXX';
export const defaultPassportNumberMaskPattern = '*******';
export const regexCreditCards = [
  {
    name: 'AMEX',
    pattern: '3[47]([0-9][ -]*?){13}',
    maskPattern: defaultCreditCardMaskPattern,
  },
  {
    name: 'BCGLOBAL',
    pattern: '(6541|6556)[ -]*?([0-9][ -]*?){12}$',
    maskPattern: defaultCreditCardMaskPattern,
  },
  {
    name: 'Carte Blanche Card',
    pattern: '389([0-9][ -]*?){11}',
    maskPattern: defaultCreditCardMaskPattern,
  },
  {
    name: 'Diners Club Card',
    pattern: '3(?:0[0-5]|[68][0-9][ -]*?)([0-9][ -]*?){11}',
    maskPattern: defaultCreditCardMaskPattern,
  },
  {
    name: 'Discover Card',
    pattern:
      '65[4-9]([0-9][ -]*?){13}|64[4-9]([0-9][ -]*?){13}|6011[ -]*?([0-9][ -]*){12,13}|(622(?:1[ -]*?2[6-9]|1[ -]*?[3-9][0-9]|[2-8][ -]*?[0-9][0-9]|9[ -]*?[01][0-9]|9[ -]*?2[0-5])[ -]*?([0-9][ -]*?){10})',
    maskPattern: defaultCreditCardMaskPattern,
  },
  {
    name: 'Insta Payment Card',
    pattern: '63[7-9]([0-9][ -]*?){13}',
    maskPattern: defaultCreditCardMaskPattern,
  },
  {
    name: 'JCB Card',
    pattern: '(?:2131|1800|35(\\d[ -]*?){3})[ -]*?(\\d[ -]*?){11}',
    maskPattern: defaultCreditCardMaskPattern,
  },
  {
    name: 'KoreanLocalCard',
    pattern: '9([0-9][ -]*?){15}',
    maskPattern: defaultCreditCardMaskPattern,
  },
  {
    name: 'Laser Card',
    pattern: '(6304|6706|6709|6771)[ -]*?([0-9][ -]*){12,15}',
    maskPattern: defaultCreditCardMaskPattern,
  },
  {
    name: 'Maestro Card',
    pattern: '(5018|5020|5038|6304|6759|6761|6763)[ -]*?([0-9][ -]*){8,15}',
    maskPattern: defaultCreditCardMaskPattern,
  },
  {
    name: 'Mastercard',
    pattern:
      '(5[1-5]([0-9][ -]*?){14}|2(22[1-9][ -]*?([0-9][ -]*?){12}|2[3-9]([0-9][ -]*?){13}|[3-6]([0-9][ -]*?){14}|7[0-1]([0-9]){13}|720([0-9][ -]*?){12}))',
    maskPattern: defaultCreditCardMaskPattern,
  },
  {
    name: 'Solo Card',
    pattern:
      '(6334[ -]*?|6767[ -]*?)([0-9][ -]*?){15}|(6334|6767)[ -]*?([0-9][ -]*?){14}|(6334|6767)[ -]*?([0-9][ -]*?){12}',
    maskPattern: defaultCreditCardMaskPattern,
  },
  {
    name: 'Union Pay Card',
    pattern: '(62([0-9][ -]*?){14,17})',
    maskPattern: defaultCreditCardMaskPattern,
  },
  {
    name: 'VISA CARD',
    pattern: '4([0-9][ -]*?){12}(?:([0-9][ -]*?){3})?',
    maskPattern: defaultCreditCardMaskPattern,
  },
  {
    name: 'Visa Master Card',
    pattern: '(?:4([0-9][ -]*?){12}(?:([0-9][ -]*?){3})?|5[1-5][ -]*?([0-9][ -]*?){14})',
    maskPattern: defaultCreditCardMaskPattern,
  },
  {
    name: 'Miles&Smiles',
    pattern: '[T][K][ -]*?([0-9][ -]*?){9}',
    maskPattern: defaultCreditCardMaskPattern,
  },
];

export const regexTCKN = [
  {
    name: 'TURKISH IDENTITY CARD',
    pattern: '([1-9][ ]*?){1}([0-9][ ]*?){9}[02468]{1}',
    maskPattern: '***********',
  },
];

export const regexEmail = [
  {
    name: 'EMAIL ADDRESS',
    pattern: /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/,
    maskPattern: '****@*****.com',
  },
];

export const regexPhoneNumbers = [
  {
    name: 'CELL PHONE',
    pattern: '(\\+\\d{1,2}\\s)?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?([0-9]){2}[\\s.-]?([0-9]){2}',
    maskPattern: '(***)-***-**-**',
  },
  {
    name: 'LANDLINE PHONE',
    pattern: '(0)([2348]{1})([0-9]{2})\\s?([0-9]{3})\\s?([0-9]{2})\\s?([0-9]{2})',
    maskPattern: '(***)-***-**-**',
  },
];

export const regexPassportNumbers = [
  {
    name: 'ALGERIA',
    pattern: '\\d{9}',
    maskPattern: defaultPassportNumberMaskPattern,
  },
  {
    name: 'ARMENIA',
    pattern: '[A-Z]{2}\\d{7}',
    maskPattern: defaultPassportNumberMaskPattern,
  },
  {
    name: '[A-Z]{3}\\d{6}',
    pattern: 'ARGENTINA',
    maskPattern: defaultPassportNumberMaskPattern,
  },
  {
    name: 'AUSTRIA',
    pattern: '[A-Z]\\d{7}',
    maskPattern: defaultPassportNumberMaskPattern,
  },
  {
    name: 'AUSTRALIA',
    pattern: '[A-Z]\\d{7}',
    maskPattern: defaultPassportNumberMaskPattern,
  },
  {
    name: 'BELGIUM',
    pattern: '[A-Z]{2}\\d{6}',
    maskPattern: defaultPassportNumberMaskPattern,
  },
  {
    name: 'BULGARIA',
    pattern: '\\d{9}',
    maskPattern: defaultPassportNumberMaskPattern,
  },
  {
    name: 'BRAZIL',
    pattern: '[A-Z]{2}\\d{6}',
    maskPattern: defaultPassportNumberMaskPattern,
  },
  {
    name: 'BELARUS',
    pattern: '[A-Z]{2}\\d{7}',
    maskPattern: defaultPassportNumberMaskPattern,
  },
  {
    name: 'CANADA',
    pattern: '[A-Z]{2}\\d{6}',
    maskPattern: defaultPassportNumberMaskPattern,
  },
  {
    name: 'SWITZERLAND',
    pattern: '[A-Z]\\d{7}',
    maskPattern: defaultPassportNumberMaskPattern,
  },
  {
    name: 'CHINA',
    pattern: 'G\\d{8}$|^E(?![IO])[A-Z0-9]\\d{7}',
    maskPattern: defaultPassportNumberMaskPattern,
  },
  {
    name: 'CYPRUS',
    pattern: '[A-Z](\\d{6}|\\d{8})',
    maskPattern: defaultPassportNumberMaskPattern,
  },
  {
    name: 'CZECH REPUBLIC',
    pattern: '\\d{8}',
    maskPattern: defaultPassportNumberMaskPattern,
  },
  {
    name: 'Germany',
    pattern: '[CFGHJK0-9][0-9]{3}[0-9C-HJ-NPRTV-Z]{5}[0-9]',
    maskPattern: defaultPassportNumberMaskPattern,
  },
  {
    name: 'DENMARK',
    pattern: '\\d{9}',
    maskPattern: defaultPassportNumberMaskPattern,
  },
  {
    name: 'ESTONIA',
    pattern: '([A-Z]\\d{7}|[A-Z]{2}\\d{7})',
    maskPattern: defaultPassportNumberMaskPattern,
  },
  {
    name: 'SPAIN',
    pattern: '[A-Z0-9]{2}([A-Z0-9]?)\\d{6}',
    maskPattern: defaultPassportNumberMaskPattern,
  },
  {
    name: 'FINLAND',
    pattern: '[0-9]{12}',
    maskPattern: defaultPassportNumberMaskPattern,
  },
  {
    name: 'FRANCE',
    pattern: '\\d{2}[A-Z]{2}\\d{5}',
    maskPattern: defaultPassportNumberMaskPattern,
  },
  {
    name: 'UNITED KINGDOM',
    pattern: '\\d{7}[A-Z]{2}',
    maskPattern: defaultPassportNumberMaskPattern,
  },
  {
    name: 'GREECE',
    pattern: '[A-Z]{2}\\d{7}',
    maskPattern: defaultPassportNumberMaskPattern,
  },
  {
    name: 'CROATIA',
    pattern: '\\d{9}',
    maskPattern: defaultPassportNumberMaskPattern,
  },
  {
    name: 'HUNGARY',
    pattern: '[A-Z]{2}(\\d{6}|\\d{7})',
    maskPattern: defaultPassportNumberMaskPattern,
  },
  {
    name: 'IRELAND',
    pattern: '[A-Z0-9]{2}\\d{7}',
    maskPattern: defaultPassportNumberMaskPattern,
  },
  {
    name: 'INDIA',
    pattern: '[A-Z]{1}-?\\d{7}',
    maskPattern: defaultPassportNumberMaskPattern,
  },
  {
    name: 'INDONESIA',
    pattern: '[A-C]\\d{7}',
    maskPattern: defaultPassportNumberMaskPattern,
  },
  {
    name: 'IRAN',
    pattern: '[A-Z]\\d{8}',
    maskPattern: defaultPassportNumberMaskPattern,
  },
  {
    name: 'ICELAND',
    pattern: '(A)\\d{7}',
    maskPattern: defaultPassportNumberMaskPattern,
  },
  {
    name: 'ITALY',
    pattern: '[A-Z0-9]{2}\\d{7}',
    maskPattern: defaultPassportNumberMaskPattern,
  },
  {
    name: 'JAPAN',
    pattern: '[A-Z]{2}\\d{7}',
    maskPattern: defaultPassportNumberMaskPattern,
  },
  {
    name: 'SOUTH KOREA',
    pattern: '[MS]\\d{8}',
    maskPattern: defaultPassportNumberMaskPattern,
  },
  {
    name: 'LITHUANIA',
    pattern: 'I[A-Za-z0-9]{5}\\d\\D',
    maskPattern: defaultPassportNumberMaskPattern,
  },
  {
    name: 'LUXEMBURG',
    pattern: 'I[A-Za-z0-9]{5}\\d\\D',
    maskPattern: defaultPassportNumberMaskPattern,
  },
  {
    name: 'LATVIA',
    pattern: '[A-Z0-9]{2}\\d{7}',
    maskPattern: defaultPassportNumberMaskPattern,
  },
  {
    name: 'MALTA',
    pattern: '\\d{7}',
    maskPattern: defaultPassportNumberMaskPattern,
  },
  {
    name: 'MOZAMBIQUE',
    pattern: '([A-Z]{2}\\d{7})|(\\d{2}[A-Z]{2}\\d{5})',
    maskPattern: defaultPassportNumberMaskPattern,
  },
  {
    name: 'MALAYSIA',
    pattern: '[AHK]\\d{8}',
    maskPattern: defaultPassportNumberMaskPattern,
  },
  {
    name: 'NETHERLANDS',
    pattern: '[A-Z]{2}[A-Z0-9]{6}\\d',
    maskPattern: defaultPassportNumberMaskPattern,
  },
  {
    name: 'POLAND',
    pattern: '[A-Z]{3}\\d{6}',
    maskPattern: defaultPassportNumberMaskPattern,
  },
  {
    name: 'PORTUGAL',
    pattern: '[A-Z]\\d{6}',
    maskPattern: defaultPassportNumberMaskPattern,
  },
  {
    name: 'ROMANIA',
    pattern: '\\d{8,9}',
    maskPattern: defaultPassportNumberMaskPattern,
  },
  {
    name: 'RUSSIAN FEDERATION',
    pattern: '\\d{9}',
    maskPattern: defaultPassportNumberMaskPattern,
  },
  {
    name: 'SWEDEN',
    pattern: '\\d{8}',
    maskPattern: defaultPassportNumberMaskPattern,
  },
  {
    name: 'SLOVANIA',
    pattern: '(P)[A-Z]\\d{7}',
    maskPattern: defaultPassportNumberMaskPattern,
  },
  {
    name: 'SLOVAKIA',
    pattern: '[0-9A-Z]\\d{7}',
    maskPattern: defaultPassportNumberMaskPattern,
  },
  {
    name: 'TURKEY',
    pattern: '[A-Z][0-9]{6}([0-9]{2})?',
    maskPattern: defaultPassportNumberMaskPattern,
  },
  {
    name: 'UKRAINE',
    pattern: '[A-Z]{2}\\d{6}',
    maskPattern: defaultPassportNumberMaskPattern,
  },
  {
    name: 'UNITED STATES',
    pattern: '\\d{9}',
    maskPattern: defaultPassportNumberMaskPattern,
  },
];
