import { XhrError } from './error';

const JSON_RESPONSE = 'json';
const TEXT_RESPONSE = 'text';

function parse(req, responseType) {
  if (req.statusText === 204 || req.statusText === 205) {
    return null;
  }
  switch (responseType) {
    case JSON_RESPONSE:
      return JSON.parse(req.responseText);
    case TEXT_RESPONSE:
      return req.responseText;
    default:
      return req.response;
  }
}

export function request(url, options = {}, responseType) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    if (typeof options.progress === 'function') {
      xhr.upload.onprogress = options.progress;
    }
    xhr.open(options.method || 'GET', url, true);
    if (options.headers) {
      Object.keys(options.headers).forEach((key) => {
        xhr.setRequestHeader(key, options.headers[key]);
      });
    }
    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        if (options.rawResponse) {
          resolve(xhr);
        } else {
          resolve(parse(xhr, responseType));
        }
      } else {
        reject(new XhrError(xhr));
      }
    };

    xhr.onerror = () => reject(new XhrError(xhr));
    xhr.ontimeout = () => reject(new Error('Request timed out'));
    xhr.onabort = () => reject(new Error('Request was aborted'));

    xhr.onloadend = () => {
      if (xhr.status === 0) {
        reject(new Error('Network failure'));
      }
    };

    xhr.send(options.body);
  });
}

export function requestJson(url, options) {
  return request(url, options, JSON_RESPONSE);
}

export function requestText(url, options) {
  return request(url, options, TEXT_RESPONSE);
}
