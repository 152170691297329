export default () => {
  let hooks = {};
  return {
    configureHooks(_hooks) {
      hooks = _hooks;
    },
    getHooks() {
      return hooks;
    },
  };
};
