/**
 *
 * LanguageModal
 *
 */

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Translate from '../../containers/Translate/Loadable';

export function LanguageModal({ languages, selectedLanguageCode, hideFn, setLanguage }) {
  const getLanguageClass = (lang) => classNames('lang-list-item', { selected: lang.code === selectedLanguageCode });

  return (
    <div className="modal-overlay" onClick={() => hideFn()} role="button" tabIndex={0}>
      <div className="modal-body">
        <h2>
          <Translate message="langSelectTitlePre" />
        </h2>
        <h1>
          <Translate message="langSelectTitle" />
        </h1>
        {languages.map((lang) => (
          <div
            className={getLanguageClass(lang)}
            onClick={() => setLanguage(lang)}
            role="button"
            tabIndex={0}
            key={lang.code}
          >
            <span className="lang-name">{lang.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

LanguageModal.propTypes = {
  languages: PropTypes.array.isRequired,
  selectedLanguageCode: PropTypes.string.isRequired,
  hideFn: PropTypes.func.isRequired,
  setLanguage: PropTypes.func.isRequired,
};

export default LanguageModal;
