import trackers from './trackers';

export default (store) => (next) => (action) => {
  // If action doesn't have an `analytics` key, ignore it.
  if (!action.analytics) {
    return next(action);
  }
  const state = store.getState();
  // Don't track anything on preview mode
  if (!state.flow.isPreview) {
    trackers.forEach((tracker) => tracker(action, state));
  }
  return next(action);
};
