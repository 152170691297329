import localStorage from '@pisano/feedback-core/app/utils/localStorage';

export function handleWidgetStore(nodeId, payload = {}) {
  const stores = localStorage.getItem('widgetStores');

  const store = {};
  store[nodeId] = { ...getWidgetStore(nodeId), ...payload };
  localStorage.setItem('widgetStores', { ...stores, ...store });

  return getWidgetStore(nodeId);
}

export function getWidgetStore(nodeId) {
  const stores = localStorage.getItem('widgetStores');

  return stores && stores[nodeId] ? stores[nodeId] : {};
}
