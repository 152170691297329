/**
 *
 * ArchiveModal
 *
 */

import Translate from '@pisano/feedback-core/app/containers/Translate/Loadable';
import PropTypes from 'prop-types';
import React from 'react';
import Alert from './images/alert.svg';

export class ArchiveModal extends React.PureComponent {
  render() {
    const { hideFn, sendFn } = this.props;
    return (
      <div className="archive-modal-overlay" onClick={() => hideFn()} role="button" tabIndex={0}>
        <div className="archive-modal-body">
          <div className="archive-icon">
            <Alert className="archive-alert" />
          </div>
          <p className="archive-title">
            <Translate message="archiveHeader" />
          </p>
          <p className="archive-subtitle">
            <Translate message="archiveMessage" />
          </p>
          <div className="button-group">
            <button type="button" className="archive-cancel">
              <Translate message="no" />
            </button>
            <button type="button" className="archive-accept" onClick={() => sendFn()}>
              <Translate message="yes" />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

ArchiveModal.propTypes = {
  hideFn: PropTypes.func.isRequired,
  sendFn: PropTypes.func.isRequired,
};
