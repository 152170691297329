/**
 *
 * LanguageToggle
 *
 */

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { CSSTransitionGroup } from 'react-transition-group';
import LanguageModal from '../../components/LanguageModal';
import { setLanguage, toggleLanguageSelector } from '../../reducers';
import { createError, ReportedError } from '../../utils/error';
import languageIcon from './images/icon@language@2x.png';

export const LANGUAGE_SELECTOR = {
  MODAL: 'MODAL',
  DROPDOWN_UP: 'DROPDOWN_UP',
  DROPDOWN_DOWN: 'DROPDOWN_DOWN',
};
const DROPDOWN_DIRECTION = { UP: 0, DOWN: 1 };

export class LanguageToggle extends React.PureComponent {
  renderLanguageModal() {
    const { languages, selectedLanguage, setLanguage, toggleLanguageSelector, isLanguageSelectorVisible } = this.props;
    return (
      <CSSTransitionGroup
        component="div"
        transitionName="modal"
        transitionEnterTimeout={250}
        transitionLeaveTimeout={250}
      >
        {isLanguageSelectorVisible && (
          <LanguageModal
            languages={languages}
            selectedLanguageCode={selectedLanguage.code}
            setLanguage={setLanguage}
            hideFn={toggleLanguageSelector}
          />
        )}
      </CSSTransitionGroup>
    );
  }

  renderLanguageDropdown(direction) {
    const { isLanguageSelectorVisible, languages, setLanguage, selectedLanguage, toggleLanguageSelector } = this.props;

    const getLanguageClass = (lang) =>
      classNames('button', 'button--dropdown', {
        'is-selected': lang.code === selectedLanguage.code,
      });
    const dropdownOptionsClass = classNames('dropdown__options', 'dropdown--language__options', {
      'directed-up': direction === DROPDOWN_DIRECTION.UP,
    });
    const languageOnClick = (lang) => {
      setLanguage(lang);
      toggleLanguageSelector();
    };

    return (
      <CSSTransitionGroup
        component="div"
        className="dropdown--language"
        transitionName="dropdown__options"
        transitionEnterTimeout={250}
        transitionLeaveTimeout={250}
      >
        {isLanguageSelectorVisible && (
          <div className={dropdownOptionsClass}>
            {languages.map((lang) => (
              <div
                className={getLanguageClass(lang)}
                onClick={() => languageOnClick(lang)}
                role="button"
                tabIndex={0}
                key={lang.code}
              >
                <span className="lang-name">{lang.name}</span>
              </div>
            ))}
          </div>
        )}
      </CSSTransitionGroup>
    );
  }

  renderLanguageSelector() {
    const { languageSelector } = this.props;
    switch (languageSelector) {
      case LANGUAGE_SELECTOR.MODAL:
        return this.renderLanguageModal();
      case LANGUAGE_SELECTOR.DROPDOWN_UP:
        return this.renderLanguageDropdown(DROPDOWN_DIRECTION.UP);
      case LANGUAGE_SELECTOR.DROPDOWN_DOWN:
        return this.renderLanguageDropdown(DROPDOWN_DIRECTION.DOWN);
      default:
        throw createError(ReportedError, 'systemError', `Unexpected language selector: ${languageSelector}`);
    }
  }

  render() {
    const { languages, selectedLanguage, setLanguage, toggleLanguageSelector, className } = this.props;

    if (!languages || languages.length < 2) {
      return null;
    }

    // Quick switch if there are 2 languages.
    if (languages.length === 2) {
      const otherLanguage = languages.find((l) => l.code !== selectedLanguage.code);
      return (
        <div
          className={`lang-button-wrapper ${className}`}
          onClick={() => setLanguage(otherLanguage)}
          role="button"
          tabIndex={0}
        >
          <img src={languageIcon} className="lang-button-wrapper-icon" alt="Change language" />
          <div className="lang-name">{otherLanguage.name}</div>
        </div>
      );
    }

    return (
      <div className="lang-button-wrapper">
        <div className={className}>
          <div onClick={() => toggleLanguageSelector()} role="button" tabIndex={0}>
            <img src={languageIcon} alt="Change language" />
            <span className="lang-button-text">{selectedLanguage.name}</span>
          </div>
        </div>
        {this.renderLanguageSelector()}
      </div>
    );
  }
}

LanguageToggle.propTypes = {
  languages: PropTypes.array,
  selectedLanguage: PropTypes.object.isRequired,
  setLanguage: PropTypes.func.isRequired,
  languageSelector: PropTypes.oneOf(Object.values(LANGUAGE_SELECTOR)),
  toggleLanguageSelector: PropTypes.func.isRequired,
  isLanguageSelectorVisible: PropTypes.bool,
  className: PropTypes.string,
};

LanguageToggle.defaultProps = {
  languageSelector: LANGUAGE_SELECTOR.MODAL,
  isLanguageSelectorVisible: false,
};

function mapStateToProps(state) {
  return {
    languages: state.flow && state.flow.languages,
    isLanguageSelectorVisible: state.language.isSelectorVisible,
    selectedLanguage: state.language.currentLanguage,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLanguage: (language) => dispatch(setLanguage(language)),
    toggleLanguageSelector: () => dispatch(toggleLanguageSelector()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageToggle);
