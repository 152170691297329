import { addMinutes, isAfter } from 'date-fns';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { requestJson, requestText } from 'utils/request';
import { API_URL } from '../../../config';
import sagaConfig from '../../../utils/configureSaga';
import { customerLoaded, customerLoadingError, nodeLoaded, nodeLoadingError } from '../actions';
import { LOAD_NODE } from '../constants';
import { getLinkChannelStore, handleLinkChannelStore } from '../helpers/linkChannelStores.js';
import setDefaultLanguage from '../utils/setDefaultLanguage';

export const configureSaga = sagaConfig();
configureSaga.configureHooks({ postLoadHook: null, onErrorHook: null });

function simplifyNode(node) {
  let channelQuota = null;
  if (node.channel_quota) {
    channelQuota = {
      isActive: node.channel_quota.active,
      type: node.channel_quota.action_kind,
    };
  }

  return {
    id: node.id,
    name: node.name,
    logoUrl: node.logo_url,
    cssUrl: node.css_url_react,
    faviconUrl: node.favicon_url,
    idleResetTimeoutSeconds: node.idle_reset_timeout_in_seconds,
    thankYouDurationSeconds: node.thank_you_duration_in_seconds,
    defaultLanguageId: node.default_language_id,
    isOperating: node.operating,
    operating24: node.operating_24_7,
    operatingHours: node.operating_hours,
    countryCode: node.country_code,
    activationStartDate: node.activation_start_date,
    activationEndDate: node.activation_end_date,
    activationExpiredMessage: node.activation_expired_message,
    unsubscribeVisible: node.unsubscribe_visible,
    channelQuota,
    nodeType: node.type,
    preventMultipleFeedback: node.prevent_multiple_feedback,
    preventMultipleFeedbackMessage: false,
    multipleFeedbackDelayPeriod: node.multiple_feedback_delay_period,
    primaryColor: node.primary_color,
    nonOperatingHoursFlowId: node.non_operating_hours_flow_id,
    pisanoBranding: node.pisano_branding,
    cssUrlReact: node.css_url_react,
    useSignatureVerify: node.use_signature_verify,
    publicKey: node.public_key,
    isSignatureValid: node.signature_valid,
    status: node.status,
  };
}

export function* handleNodeLoadingError(err) {
  if (err.response) {
    // XHR error
    yield put(nodeLoadingError(JSON.parse(err.response)));
  } else {
    // Other JS error
    throw err;
  }
}

function* loadNode({ payload: { queryParams } }) {
  const hooks = configureSaga.getHooks();
  const flowLanguage = queryParams && queryParams.lang !== undefined ? queryParams.lang : null;

  if (queryParams.isPreview) {
    yield put(nodeLoaded({ id: 'PREVIEW' }));
    return;
  }

  try {
    const nodeRequestUrl = new URL(`${API_URL}/v1/nodes/${queryParams.node_id}`);

    if (queryParams.signature) {
      nodeRequestUrl.searchParams.append('signature', queryParams.signature);
    }

    if (queryParams.responses) {
      nodeRequestUrl.searchParams.append('responses', queryParams.responses);
    }

    const node = yield call(requestJson, nodeRequestUrl);
    const simplifiedNode = simplifyNode(node);

    const { id } = simplifiedNode;
    const linkChannelStore = getLinkChannelStore(id);
    const { firstNow } = linkChannelStore;
    let { isLinkChannelDisplayedBefore } = linkChannelStore;

    if (
      simplifiedNode.preventMultipleFeedback &&
      isLinkChannelDisplayedBefore &&
      !!simplifiedNode.multipleFeedbackDelayPeriod &&
      firstNow
    ) {
      const firstNowDate = new Date(firstNow);
      const added = addMinutes(firstNowDate, simplifiedNode.multipleFeedbackDelayPeriod);
      const periodExpired = isAfter(new Date(), added);
      if (periodExpired) {
        handleLinkChannelStore(id, { isLinkChannelDisplayedBefore: false });
      }
    }

    isLinkChannelDisplayedBefore = getLinkChannelStore(id).isLinkChannelDisplayedBefore || false;
    if (simplifiedNode.preventMultipleFeedback && isLinkChannelDisplayedBefore) {
      simplifiedNode.preventMultipleFeedbackMessage = true;
    } else {
      handleLinkChannelStore(simplifiedNode.id, { isLinkChannelDisplayedBefore: false });
    }

    if (simplifiedNode.cssUrl) {
      simplifiedNode.css = yield call(requestText, simplifiedNode.cssUrl);
    }

    const { widget } = yield select();

    // If node loading from widget and customer is already exist do not make a api call to get customer info
    if (widget && widget.customer && widget.customer.id) {
      yield put(customerLoaded(widget.customer));
    } else if (queryParams.creator_id) {
      const customerRequestUrl = `${API_URL}/v1/nodes/${queryParams.node_id}/customers/${queryParams.creator_id}`;
      try {
        const customer = yield call(requestJson, customerRequestUrl);
        yield put(customerLoaded(customer));
      } catch (err) {
        yield put(customerLoadingError(err));
      }
    }

    if (hooks.postLoadHook) {
      yield* hooks.postLoadHook(simplifiedNode);
    }
    yield put(nodeLoaded(simplifiedNode));
    yield setDefaultLanguage(yield select(), flowLanguage);
  } catch (err) {
    if (hooks.onErrorHook) {
      yield* hooks.onErrorHook({ error: err, language: flowLanguage });
    } else {
      yield call(handleNodeLoadingError, err);
    }
  }
}

export default function* nodeSaga() {
  yield takeLatest(LOAD_NODE, loadNode);
}
